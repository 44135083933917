import 'blockadblock';
import { store, EMBEDDED } from '../store';
import config from '../config';

blockAdBlock.setOption('checkOnLoad', false);
blockAdBlock.setOption('baitClass', 'pub_300x250 pub_300x250m pub_728x90 text-ad textAd text_ad text_ads text-ads text-ad-links ad_320x50');

function adBlockDetected() {
    if (!EMBEDDED) {
        store.setWarning(config.WARNINGS.ADBLOCK_DETECTED);
    }
}
if(typeof blockAdBlock === 'undefined') {
    adBlockDetected();
} else {
    blockAdBlock.onDetected(adBlockDetected);
}

export default blockAdBlock;