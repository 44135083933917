<template>
<div class="page mobile">
    <header><h1></h1></header>
    <div class="row">
        <div class="col" :class="['ad-'+adunit.size, adunit.format]" ref="adunit" id="adunit">
        </div>
    </div>
    <div class="row wrap">
        <div class="col">
            <h4 v-fill="3"></h4>
            <p v-fill="15"></p>
            <p img="1:2"></p>
            <p v-fill="50"></p>
            <p v-fill="15"></p>
        </div>
    </div>
</div>
</template>

<style lang="scss">
</style>

<script>
import TagsLoaderMixin from "../mixins/TagsLoader";

export default {
    name: 'MobileDefault',
    mixins: [TagsLoaderMixin],
    computed: {
        size() {
            return this.adunit.size;
        }
    }
}
</script>