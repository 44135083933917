import config from '../config';

export default {
    generateScreenshots(bundle) {
        const requestUrl = config.SCREENSHOTS_API_URL + '/screenshots/' + bundle.hash;
        let generateScreenshotsRequest = new Request(requestUrl, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(bundle)
        });

        return fetch(generateScreenshotsRequest)
            .then(response => response.json())
            .then(res => {
                return res;
            })
    },
    getStatus(hash) {
        const requestUrl = config.SCREENSHOTS_API_URL + '/screenshots/' + hash + '/status';
        return fetch(requestUrl)
            .then(response => response.json())
            .then(res => {
                return res;
            })
    },
    getArchiveUrl(hash, mode = 'auto', filename = 'screenshots') {
        return config.SCREENSHOTS_API_URL + `/screenshots/${hash}/archive/${mode}/${encodeURI(filename)}`;
    },
    saveScreenshot(hash, blob, meta) {
        const form = new FormData();
        form.append('screenshot', blob, 'screenshot.png');

        const requestUrl = config.SCREENSHOTS_API_URL + `/screenshots/${hash}/manual/${meta.product}/${meta.size}/${meta.name}`;
        let generateScreenshotsRequest = new Request(requestUrl, {
            method: 'POST',
            body: blob
        });

        return fetch(generateScreenshotsRequest)
            .then(response => response.json())
            .then(res => {
                return res;
            })
    },
    createArchive(hash) {
        const requestUrl = config.SCREENSHOTS_API_URL + `/screenshots/${hash}/archive/manual`;
        let generateScreenshotsRequest = new Request(requestUrl, {
            method: 'POST'
        });

        return fetch(generateScreenshotsRequest)
            .then(response => response.json())
            .then(res => {
                return res;
            })
    }
}