<template>
    <div class="presentation-mode">
        <label class="switch">
            <input type="checkbox" :checked="isPresentationMode" @change="togglePresentationMode">
            <span class="slider round"></span>
            Presentation Mode
        </label>
    </div>
</template>
<style scoped>
  .presentation-mode { 
    height: 40px; 
    padding: 0 25px; 
    line-height: 40px; 
    font-size: 12px; 
    color: #9c9fa0; 
    border-bottom: 1px solid #EEEEEE;
  }
  .switch {
    position: relative;
    display: inline-block;
    height: 16px;
    font-size: 12px;
    font-weight: 100;
    color: #595F63;
  }
  .switch input { opacity: 0; width: 0; height: 0; }
  .slider {
    position: relative;
    left: -3px;
    display: inline-block;
    vertical-align: sub;
    width: 32px;
    cursor: pointer;
    height: 16px;
    background-color: #ccc;
    transition: .4s;
    margin-right: 13px;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    transition: .4s;
  }
  input:checked + .slider { background-color: #7FC268; }
  input:focus + .slider { box-shadow: 0 0 1px #7FC268; }
  input:checked + .slider:before { transform: translateX(16px); }
  .slider.round { border-radius: 16px; }
  .slider.round:before { border-radius: 50%; }

  @media only screen and (max-device-width: 480px) {
    .presentation-mode { display: none; }
  }
</style>
<script>
import { store } from "../store";
import Stats from "../stats";

export default {
    name: 'PresentationMode',
    computed: {
      isPresentationMode: () => store.state.presentationMode
    },
    methods: {
      async togglePresentationMode() {
        await store.togglePresentationMode();
        Stats.send('feature', {
            'name': 'TOGGLE_PRESENTATION_MODE',
            'value': store.state.presentationMode
        });
        setTimeout(() => {window.location.reload()}, 100);
      }
    }
}
</script>