<template>
<div class="page" :style="style">
    <div id="scroll-down-hint" ref="scroll-down-hint" :style="style">Scroll down to view the ad</div>
    <header><h1></h1></header>
    <div class="row">
        <div class="col">
            <h5 v-fill="3"></h5>
            <p v-fill="15"></p>
            <p v-fill="35"></p>
            <p img="1:2"></p>
        </div>
        <div class="col">
            <h4 v-fill="3"></h4>
            <p img="1:2"></p>
            <p v-fill="15"></p>
            <p v-fill="35"></p>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <p v-fill="100"></p>
            <p v-fill="100"></p>
            <p v-fill="100"></p>
            <div class="ad-player" id="adunit">
                <div id="MainVideoContainer" ref="videoContainer" style="transition: all 1s linear; -webkit-transition:all .5s linear;-moz-transition:all 1s linear;-o-transition:all 1s linear;height: 1px;width: 582px;position: relative; overflow: hidden; margin: 0 auto;">
                    <video-js id="vid2" ref="video"  class="video-js vjs-default-skin  vjs-big-play-centered vjs-16-9"  autoplay: true preload="auto" data-setup='{}' width='581px' height='328px'>
                        <source src="../instreamcontent.mp4" type='video/mp4' >
                        <p>Video Playback Not Supported</p>
                    </video-js>
                </div>
            </div>
            <p v-fill="100"></p>
            <p v-fill="100"></p>
        </div>
    </div>
</div>
</template>

<style lang="scss" scoped>
    .page header {
        margin-top: 40px;
    }
    ::-webkit-scrollbar { width: 0; background: transparent; }
    ::-webkit-scrollbar-thumb { background: transparent }
    #scroll-down-hint {
        top: 0px;
        height: 35px;
        background: #405de6;
        padding: 0px 10px;
        line-height: 35px;
        color: #fff;
        text-align: center;
        position: fixed; 
        width: 970px;
        left: 50%;
        margin-left: -332px;
        font-size: 17px;
    }
</style>

<script>
import config from '../../config';
import InreadMixin from '../mixins/Inread';

export default {
    name: 'DesktopInread',
    mixins: [InreadMixin],
    props: {
        adunit: {
            type: Object,
            default() {
                return {
                    mediaDataID: 0,
                    adformat: '',
                    size: '',
                    previewJson: false
                };
            }
        },
        topOffset: {
            type: Number
        }
    },
    computed: {
        style() {
            return 'margin-top: ' + this.topOffset + 'px';
        }
    },
    mounted() {
        this.render();
    }
}
</script>
