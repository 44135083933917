<template>
    <div class="qr-container" v-if="isShowQr">
        <span>To view on <br/> your device<br/></span>
        <img src="/img/qrcode_small.svg"/>
        <div v-html="qrcode" class="qr"></div>
    </div>
</template>
<style scoped lang="scss">
  .qr-container {
    border-bottom: 1.5px solid #80808099;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 10px;
    span {
        text-transform: uppercase;
        margin-right: 10px;
        font-size: 10px;
        font-weight: 500;
    }
    .qr {
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        transition: opacity .5s;
        }
    }

    .qr-container:hover .qr {
       opacity: 1;
    }
</style>
<script>
import { store } from '../store';
import QRCode from 'qrcode-svg';

export default {
    name: 'Qr',
    data() {
        return {
            qrcode: '',
            state: store.state,
        }
    },
    computed: {
        isShowQr() {
            let params= window.location.hash.substr(1).split('/')
            if (params && params.length > 1) {
                let adunit = this.state.bundle.urls[params[1]];
                return adunit.format === "preroll" && adunit.platform ==="mobile";
            } else {
                return false;
            }
        }
    },
    mounted: function () {
        this.generateQRCode()
    },
    methods: {
        generateQRCode() {
            const url = new URL(window.location.href);
            url.searchParams.set('qr', true);
            // Check if showcase loads custom bundle via json link
            // If yes encode the link to avoid an issue of non encoded links on mobile devices
            const searchParams = new URLSearchParams(url.search);
            if (searchParams.has('json')) {
                url.search = searchParams.toString();
            }
            let qrcode = new QRCode(url.href);
            this.qrcode = qrcode.svg();
        }
    }
}
</script>