import config from "../../config";
import { store } from "../../store";

/**
 * @type {Array<[number, string]>}
 */
const productIdNameMap = [
    [2, "VdxDesktopInframe"],
    [14, "VdxDesktopExpandable"],
    [5, "VdxMobileInframe"],
    [17, "VdxMobileExpandable"],
    [38, "VdxDesktopInstream"],
    [41, "VdxMobileInstream"],
    [54, "CTV"],
    [55, "OTT"],
];

function isObject(item) {
    return item && typeof item === "object" && !Array.isArray(item);
}

function mergeDeep(target, source) {
    let result = Object.assign({}, target);
    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!(key in target)) {
                    Object.assign(result, { [key]: source[key] });
                } else {
                    result[key] = mergeDeep(target[key], source[key]);
                }
            } else {
                Object.assign(result, { [key]: source[key] });
            }
        }
    }
    return result;
}

export class E9Tags {
    /**
     * @param {Adunit} adunit
     */
    constructor(adunit) {
        this.isPreview = !!adunit.previewJson;
        this.type = adunit.type;
        this.platform = adunit.platform;
        this.params = {
            productId: 0,
            mediaDir: adunit.mediaDir,
            adformat: adunit.adformat,
            size: adunit.size,
            noAd: 1,
            clientID: 293233,
            playTrackURL: "https://",
            clickTrackURL: "https://",
            flushMedia: 1,
            environment: config.ENV,
            tagparams: {
                productControlConfig: {},
            },
        };

        if (!store.state.isVDXStudioPreviewFlow) {
            this.params.tagparams.productControlConfig.isBokehModeEnabled = adunit.config.isBokehMode;
        }

        if (store.state.isCreativeConceptFlow && this.type !== "flight") {
            this.params.ccRevisionVariation = adunit.ccRevisionVariation;
            this.params.ccRevision = adunit.ccRevision;
            this.params.vdxFrameworkTemplateVersion = adunit.vdxFrameworkTemplateVersion;
            this.params.mediaID = adunit.mediaID;
        }

        this.custom = {
            tagparams: {},
        };
        if (adunit.adformat) {
            this.setProductName(adunit.adformat);
        }
        if (!store.state.isMobile && adunit.adformat.toLowerCase().indexOf("mobile") !== -1) {
            this.params.tagparams.productControlConfig.showcaseWithIphoneSkin = true;
        }
        this.vastUrl = adunit.config.vastUrl || config.VPAID_URL;

        switch (this.type) {
            case "expotask":
                this.params.mediaType = 8;
                this.params.mediaDataID = adunit.mediaDataID;

                if (store.state.isCreativeConceptFlow) {
                    if (store.state.creativeConceptFlowType === "publish") {
                        this.params.adApp = 1;
                    } else {
                        this.params.mockApp = 1;
                    }
                } else {
                    this.params.mockApp = 1;
                }
                break;
            case "expo9":
                this.params.mediaType = 22;
                this.params.mediaDataID = adunit.mediaDataID;
                this.params.playTrackURL = "//tribalfusion.com/adapp/tf/campaign/play/4508644X9506635X9420106/";
                this.params.clickTrackURL = "//tribalfusion.com/adapp/tf/campaign/click/4508644X9506635X9420106/";

                if (store.state.isCreativeConceptFlow) {
                    if (store.state.creativeConceptFlowType === "publish") {
                        this.params.adApp = 1;
                    } else {
                        this.params.mockApp = 1;
                    }
                } else {
                    this.params.adApp = 0;
                }
                break;
            case "flight":
                this.id = adunit.mediaDataID;
                this.params.consent = "&dealId=" + adunit.mediaDataID;
                this.params.env = "display";
        }
        if (window !== top) {
            this.params.saleskit = 1;
        }
        if (this.params.size === "320x50" || (this.params.size === "768x90" && adunit.platform === "mobile")) {
            this.params.snackbar = 1;
        }
        if (adunit.tagparams) {
            this.custom.tagparams = JSON.parse(JSON.stringify(adunit.tagparams));
        } else if (adunit.config.tagparams) {
            this.custom.tagparams = JSON.parse(JSON.stringify(adunit.config.tagparams));
        }
    }
    setCustomTags(tags) {
        this.params = Object.assign(this.params, tags);
    }
    enablePresentationMode() {
        if (!store.state.isMobile) {
            this.params.tagparams.renderingenv = "showcase";
            this.params.tagparams.productcontrolconfig = {
                isofftabautoswitch: {
                    enabled: true,
                },
            };
            this.params.tagparams.productControlConfig.isTurnOffTabAutoSwitch = true;
        }
    }
    setProductName(productName) {
        if (this.params.adformat.toLowerCase() === "vdxconnectinstream") {
            this.params.tagparams.productControlConfig.productName = "VdxDesktopInstream";
        } else if (productName === "PrerollHtml5Desktop") {
            this.params.tagparams.productControlConfig.productName = "VdxDesktopPreroll";
        } else {
            this.params.tagparams.productControlConfig.productName = productName.replace("Connect", "");
        }
        if (this.isPreview) {
            this.params.tagparams.productControlConfig.isPlayFragmentedVideo = false;
        }

        const pName = this.params.tagparams.productControlConfig.productName;
        const productId = productIdNameMap.find((x) => x[1].toLowerCase() === pName.toLowerCase());

        if (productId) {
            this.params.productId = productId[0];
        }
    }
    enableFrameBusting() {
        if (this.params.tagparams?.productControlConfig?.frameBustOnLoad !== undefined) {
            delete this.params.tagparams.productControlConfig.frameBustOnLoad;
        }
        if (this.params.tagparams?.productControlConfig?.floatOnLoad !== undefined) {
            delete this.params.tagparams.productControlConfig.floatOnLoad;
        }
    }
    disableFrameBusting() {
        this.params.tagparams.productControlConfig.frameBustOnLoad = false;
        this.params.tagparams.productControlConfig.floatOnLoad = false;
        this.params.tagparams.productControlConfig.showcaseWithIphoneSkin = true;
    }
    getVPAIDUrl() {
        let url = this.vastUrl + "?";
        if (["TV", "OTT"].includes(this.platform)) {
            url += "vpaid=0";
            // Showcase has to send plt parameter to Webserver only if CTV/OTT is created in multi media flow
            // In this case VDX Studio passes product name (adformat) as CTV or OTT
            if (["CTV", "OTT"].includes(this.params.adformat)) {
                url += "&plt=" + (this.platform === "TV" ? "ctv" : "ott");
            }
        } else {
            url += "vpaid=1";
        }

        switch (this.type) {
            case "flight":
                url +=
                    "&site=TestPage&adSpace=TestPage1" +
                    "&dealId=" +
                    this.id +
                    "&platform_version=vast20html5&p9_param0=bidderpreview";
                break;
            case "expo9":
                const expo9AppType = (() => {
                    if (store.state.isCreativeConceptFlow) {
                        return store.state.creativeConceptFlowType === "publish" ? "adApp" : "mockApp";
                    } else {
                        return "adApp";
                    }
                })();

                const expo9ProductId = store.state.isCreativeConceptFlow ? this.params.productId : "41";

                /**
                 * @type Array<[string, string]>
                 */
                const expo9Params = [
                    ["clickTrackURL", this.params.clickTrackURL],
                    [expo9AppType, "1"],
                    ["productId", expo9ProductId],
                    ["mediaDataID", this.params.mediaDataID],
                    ["clientID", this.params.clientID],
                    ["env", "instream"],
                    ["size", this.params.size],
                    ["playTrackUrl", this.params.playTrackURL],
                    ["fileName", "preroll.vpaid.xml"],
                ];

                url += "&" + expo9Params.map((x) => x.join("=")).join("&");
                break;
            case "expotask":
                const expoTaskAppType = (() => {
                    if (store.state.isCreativeConceptFlow) {
                        return store.state.creativeConceptFlowType === "publish" ? "adApp" : "mockApp";
                    } else {
                        return "mockApp";
                    }
                })();

                /**
                 * @type Array<[string, string]>
                 */
                const params = [
                    ["clickTrackURL", this.params.clickTrackURL],
                    [expoTaskAppType, "1"],
                    ["mediaType", "24"],
                    ["mediaDataID", this.params.mediaDataID],
                    ["clientID", this.params.clientID],
                    ["env", "instream"],
                    ["size", this.params.size],
                    ["playTrackUrl", this.params.playTrackURL],
                    ["fileName", "preroll.vpaid.xml"],
                ];

                if (store.state.isCreativeConceptFlow) params.push(["productId", this.params.productId.toString()]);

                url += "&" + params.map((x) => x.join("=")).join("&");
        }

        if (store.state.isCreativeConceptFlow && this.type !== "flight") {
            const params = [
                ["ccRevision", this.params.ccRevision],
                ["ccRevisionVariation", this.params.ccRevisionVariation],
                ["vdxFrameworkTemplateVersion", this.params.vdxFrameworkTemplateVersion],
                ["mediaID", this.params.mediaID],
            ];

            url += "&" + params.map((x) => x.join("=")).join("&");
        }

        return url;
    }
    mergeCustomTagparams() {
        return {
            ...this.params,
            tagparams: mergeDeep(this.params.tagparams, this.custom.tagparams),
        };
    }
    toObject() {
        return JSON.parse(JSON.stringify(this.mergeCustomTagparams()));
    }
    toJSString() {
        const params = this.mergeCustomTagparams();
        const e9tags = [];
        for (const tag in params) {
            e9tags.push(
                "e9." +
                    tag +
                    " = " +
                    (typeof params[tag] === "string" ? '"' + params[tag] + '"' : JSON.stringify(params[tag])) +
                    ";"
            );
        }
        return e9tags.join("");
    }
}
