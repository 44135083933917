<template>
<div class="preroll page mobile instream">
    <header v-if="!isBezel"><h1></h1></header>
    <div class="row">
        <div class="col ad-mobile-player" id="adunit" ref="adunit">
            <div>
                <video-js id="vid2" ref="video" webkit-playsinline playsinline class="video-js vjs-default-skin vjs-big-play-centered vjs-16-9 vjs-static-controls" controls disablePictureInPicture preload="auto" >
                    <source src="../instreamcontent.mp4" type="video/mp4" >
                    <p>Video Playback Not Supported</p>
                </video-js>
            </div>
        </div>
        
    </div>
    <ul class="video-list">
        <li>
            <span class="video"></span>
            <p class="description" v-fill="20"></p>
        </li>
        <li>
            <span class="video"></span>
            <p class="description" v-fill="20"></p>
        </li>
        <li>
            <span class="video"></span>
            <p class="description" v-fill="20"></p>
        </li>
        <li>
            <span class="video"></span>
            <p class="description" v-fill="20"></p>
        </li>
    </ul>
</div>
</template>

<style lang="scss">
    .page.mobile.instream header { display: block !important; }
    .video-js.vjs-static-controls .vid2_ima-controls-div {
        height: 30px;
        bottom: -30px;
        background-color: rgba(43,51,63,1);
    }
    #device.preroll.mobile {
        left: 0;
    }
</style>

<script>
import InstreamMixin from '../mixins/Instream';
import config from '../../config';
import EventBus from '../../event_bus';
import canAutoplay from '../../../lib/can-autoplay.min.js';

export default {
    name: 'MobileInstream',
    mixins: [InstreamMixin],
    props: {
        adunit: {
            type: Object,
            default() {
                return {
                    mediaDataID: 0,
                    adformat: '',
                    size: '',
                    previewJson: false
                };
            }
        }
    },
    data() {
        return {
            isBezel: window !== parent
        }
    },
    mounted() {
        EventBus.$once('play-mobile-instream', (isMuted) => {
            const iOS = !window.MSStream && /iPad|iPhone|iPod/.test(navigator.userAgent);
            if (!iOS) {
                canAutoplay
                    .video({
                        timeout: 500,
                        muted: false,
                        inline: true
                    })
                    .then((response) => {
                        if (response.result === false) {
                            this.render(true, true);
                        } else {
                            this.render(false, true);
                        }
                    });
            } else {
                this.render(isMuted, true);
            }
        });
    }
}
</script>
