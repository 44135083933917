<template>
    <div class="page container">
        <div class="tv" :class="{ ready: ready.tv }">
            <div class="wrap">
                <div class="col ad-player">
                    <video-js
                        id="vid2"
                        ref="video"
                        webkit-playsinline
                        playsinline
                        class="video-js vjs-default-skin vjs-big-play-centered vjs-16-9 vjs-static-controls"
                        controls
                        disablePictureInPicture
                        preload="auto"
                        width="960px"
                        height="540px"
                    >
                        <source src="../tv-content.mp4" type="video/mp4" />
                        <p>Video Playback Not Supported</p>
                    </video-js>
                </div>
            </div>
        </div>
        <div class="mobile" :class="{ ready: ready.mobile }">
            <div class="wrap">
                <iframe
                    allowfullscreen="true"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allow="autoplay"
                    ref="frame"
                    v-bind:src="url"
                ></iframe>
            </div>
        </div>
        <div class="loading" :class="{ visible: !(ready.tv || ready.mobile) }">loading...</div>
    </div>
</template>
<style lang="scss" scoped>
.container {
    display: flex;
    flex-flow: row nowrap;
    /*ustify-content: space-evenly;*/
    width: calc(100% - var(--nav-width));
    left: var(--nav-width);
}
.loading {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 100%;
    z-index: 10000000000;
    padding-top: 30%;
}
.loading.visible {
    display: block;
}
.tv {
    width: 494px;
    height: 322px;
    align-self: center;
    margin: 50px auto;
    transform: translateX(-50px);
    transition: all 0.5s ease-in-out;
    opacity: 0;
}
.tv.ready {
    transform: translateX(0);
    opacity: 1;
}
.tv .wrap {
    background: url("../../../img/ctv_tv_bg.svg");
    width: 988px;
    height: 643px;
    transform: scale(0.5);
    transform-origin: left top;
    padding: 0.1px;
}
.tv .wrap .col.ad-player {
    width: 960px;
    margin: 18px auto;
}
.tv .wrap .content {
    margin-top: 80px;
}
.tv .wrap #vid2_ima-ad-container .vjs-control.vjs-progress-control {
    pointer-events: auto;
}
.tv .wrap #vid2_ima-progress-div {
    background-color: #fff;
}

.mobile {
    transform: translateX(50px);
    transition: all 0.5s ease-in-out;
    opacity: 0;
}
.mobile.ready {
    transform: translateX(0);
    opacity: 1;
}
.mobile {
    width: 432px;
    height: 920px;
    margin: 50px auto;
    display: block;
    position: relative;
    transform-origin: 50% 0;
    align-self: center;
}
.mobile:after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    background: url("../../../img/iphone_x_bezel.png");
    background-size: contain;
    height: 920px;
    width: 433px;
    z-index: 999;
    background-repeat: no-repeat;
}
.mobile .wrap {
    position: relative;
    width: 375px;
    height: 622px;
    left: 29px;
    background: #fff;
    top: 115px;
    z-index: 1000;
}
.mobile .wrap iframe {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0 none;
}
</style>

<script>
import { E9Tags } from "../../entities/e9Tags";
import { store } from "../../store";
import Stats from "../../stats";
import QrScanner from "qr-scanner";
import Bezel from "../../components/Bezel";

const QRWorker = new Worker("../../../node_modules/qr-scanner/qr-scanner-worker.min.js");
QRWorker.postMessage({
    type: "grayscaleWeights",
    data: { red: 80, green: 96, blue: 80, useIntegerApproximation: true },
});

export default {
    name: "QRCodeExperience",
    components: { Bezel },
    props: {
        adunit: {
            type: Object,
            default() {
                return {
                    mediaDataID: 0,
                    adformat: "",
                    size: "",
                    previewJson: false,
                };
            },
        },
    },
    data() {
        return {
            player: null,
            url: "",
            ready: {
                tv: false,
                mobile: false,
            },
        };
    },
    mounted() {
        this.render();
        Stats.send("feature", {
            name: "QRCODE_EXPERIENCE",
        });
    },
    methods: {
        render() {
            // Render published adunit
            this.renderPublished();
        },
        renderPublished() {
            const self = this;
            const e9 = new E9Tags({ ...this.adunit, size: "1x10" });
            if (store.state.presentationMode) {
                e9.setCustomTags({
                    tagparams: {
                        renderingenv: "showcase",
                        productcontrolconfig: {
                            isofftabautoswitch: { enabled: true },
                        },
                    },
                });
            }
            window.e9 = e9.toObject();

            var vjs;

            const initPlayer = () => {
                if (store.state.isCreativeConceptFlow) {
                    initVideoJS();
                } else {
                    if (this.adunit.media) {
                        fetch(e9.getVPAIDUrl())
                            .then((response) => response.text())
                            .then((vast) => {
                                const xml = new window.DOMParser().parseFromString(vast, "text/xml");
                                // Replace duration
                                xml.querySelector("VAST Ad Creatives Creative Linear Duration").textContent =
                                    this.adunit.media.duration;
                                // Replace media file
                                const mediaFileNodes = xml.querySelectorAll(
                                    "VAST Ad Creatives Creative Linear MediaFiles MediaFile"
                                );
                                const newMediaFile = this.adunit.media.src;
                                for (let node of mediaFileNodes) {
                                    const mediaUrl = new URL(newMediaFile, node.textContent);
                                    const cdata = xml.createCDATASection(mediaUrl.href);
                                    node.textContent = "";
                                    node.appendChild(cdata);
                                }
                                const ctvVast = new XMLSerializer().serializeToString(xml);
                                initVideoJS(ctvVast);
                                initVideoJS(vast);
                            });
                    } else {
                        initVideoJS();
                    }
                }
            };

            const initVideoJS = (vast) => {
                vjs = videojs(self.$refs.video, {
                    inactivityTimeout: 0,
                    autoplay: false,
                    muted: true,
                    controlBar: {
                        children: ["playToggle", "timeDivider", "progressControl", "muteToggle"],
                    },
                });

                vjs.controls(true);
                setupIMA(vast);
            };

            function setupIMA(vast) {
                var options = {
                    id: "vid2",
                    adLabel: "",
                    showControlsForJSAds: true,
                    vpaidMode: google.ima.ImaSdkSettings.VpaidMode.INSECURE,
                    showCountdown: false,
                    disableAdControls: false,
                    timeout: 60000,
                    vastLoadTimeout: 60000,
                    adsManagerLoadedCallback: adsManagerLoadedCallback.bind(this),
                    debug: true,
                };

                if (vast) {
                    options.adsResponse = vast;
                } else {
                    options.adTagUrl = e9.getVPAIDUrl();
                }

                var mutationObserver = new MutationObserver(function (mutations) {
                    let stopObserve = false;
                    mutations.forEach(function (mutation) {
                        if (mutation.addedNodes.length && !stopObserve) {
                            const addedNodes = Array.from(mutation.addedNodes);
                            const limaVideoElement = addedNodes.find((node) => node.nodeName === "LIMA-VIDEO");
                            if (limaVideoElement) {
                                console.log("Lima-video has been detected - reload");
                                stopObserve = true;
                                mutationObserver.disconnect();
                                window.location.reload();
                            } else {
                                const videoElement = addedNodes.find(
                                    (node) => node.nodeName === "VIDEO" && node.title === "Advertisement"
                                );
                                if (videoElement) {
                                    videoElement.setAttribute("crossorigin", "Anonymous");
                                    videoElement.setAttribute("title", "Advertisement");
                                }
                            }
                        }
                    });
                });

                mutationObserver.observe(self.$refs.video, {
                    attributes: true,
                    characterData: true,
                    childList: true,
                    subtree: true,
                    attributeOldValue: true,
                    characterDataOldValue: true,
                });

                function adsManagerLoadedCallback() {
                    vjs.play();
                    /**
                     * @type {HTMLVideoElement}
                     */
                    let adVideo;

                    vjs.ima.addEventListener(google.ima.AdEvent.Type.STARTED, () => {
                        adVideo = document.querySelector("video[title=Advertisement]");
                        adVideo.setAttribute("crossorigin", "Anonymous");
                        adVideo.addEventListener("seeked", async () => {
                            const loadMobileBezel = (url) => {
                                self.url = url;
                                adVideo.removeEventListener("seeked", event);
                                const mobileBezelLoaded = new Promise((resolve, reject) => {
                                    self.$refs.frame.addEventListener("load", () => {
                                        resolve();
                                    });
                                });
                                const delay = new Promise((resolve) => {
                                    setTimeout(() => resolve(), 1300);
                                });
                                return Promise.all([mobileBezelLoaded, delay]);
                            };
                            self.ready.tv = true;
                            let qrCodeUrl;
                            try {
                                if (typeof self.adunit.QRCodeExperience !== "string") {
                                    qrCodeUrl = await QrScanner.scanImage(adVideo, null, QRWorker);
                                } else {
                                    qrCodeUrl = self.adunit.QRCodeExperience;
                                }

                                const qrCodeUrlParsed = new URL(qrCodeUrl);
                                if (window.location.protocol === "https:" && qrCodeUrlParsed.protocol === "http:") {
                                    qrCodeUrlParsed.protocol = "https:";
                                    qrCodeUrl = qrCodeUrlParsed.toString();
                                }

                                await loadMobileBezel(qrCodeUrl);
                                self.ready.mobile = true;
                            } catch (e) {
                                console.log(e);
                            }
                        });
                        adVideo.pause();
                        adVideo.currentTime =
                            self.adunit.frameTimestamp !== undefined
                                ? self.adunit.frameTimestamp
                                : adVideo.duration - 1;
                    });

                    vjs.ima.addEventListener(google.ima.AdEvent.Type.PAUSED, () => {});

                    vjs.ima.addEventListener(google.ima.AdEvent.Type.ALL_ADS_COMPLETED, function () {
                        vjs.controls(true);
                        vjs.play();
                    });

                    vjs.ima.addEventListener(google.ima.AdEvent.Type.COMPLETE, function () {
                        vjs.controls(true);
                        vjs.play();
                    });

                    vjs.ima.addEventListener(google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED, function () {
                        vjs.play();
                        vjs.controls(true);
                    });

                    vjs.ima.addEventListener(google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED, function () {
                        vjs.play();
                    });
                }
                vjs.ima(options);
            }

            initPlayer();

            var initAdDisplayContainer = function () {
                vjs.ima.initializeAdDisplayContainer();
                wrapperDiv.removeEventListener(startEvent, initAdDisplayContainer);
            };

            var startEvent = "click";
            if (
                navigator.userAgent.match(/iPhone/i) ||
                navigator.userAgent.match(/iPad/i) ||
                navigator.userAgent.match(/Android/i)
            ) {
                startEvent = "touchend";
            }

            var wrapperDiv = document.getElementById("vid2");
            wrapperDiv.addEventListener(startEvent, initAdDisplayContainer);
        },
    },
};
</script>
