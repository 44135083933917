import config from "./config";
import { store } from "./store";
//import './hotjar';
import blockAdBlock from "./adblockdetect";
import "./pixel";
import Vue from "vue/dist/vue.js";
import Filler from "./filler";
import Demopage from "./components/Demopage.vue";
import Navigation from "./components/Navigation";
import Bezel from "./components/Bezel.vue";
import Qr from "./components/Qr";
import Warning from "./components/Warning";
import Stats from "./stats";
import EventBus from "./event_bus";

Vue.config.silent = true;
Vue.filter("fdate", (value) => {
    const date = new Date(value);
    return date.toLocaleDateString(["en-US"], {
        month: "short",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
    }); //if you want, you can change locale date string
});
Vue.directive("fill", Filler);
Vue.component("Navigation", Navigation);
Vue.component("Bezel", Bezel);
Vue.component("Demopage", Demopage);
Vue.component("Warning", Warning);
Vue.component("Qr", Qr);

const App = new Vue({
    el: "#application",
    data() {
        return {
            state: store.state,
            size: {},
            demopageRenderer: false,
            error: null,
            remount: 0,
            singleSizeMode: false,
        };
    },
    async mounted() {
        setTimeout(() => route(), 1);
    },
    watch: {
        "state.isMobile": {
            handler(v) {
                console.log("isMobile has been changed:", this.state.isMobile);
                route(true);
            },
            immediate: false,
        },
    },
    methods: {
        showBundleDemopage(sizeId, muted = true) {
            if (sizeId === this.state.size) {
                this.remount = !this.remount;
            }

            store.setSize(sizeId);
            this.size = this.state.bundle.urls[sizeId];
            if (this.size.platform === "mobile" && this.size.format === "preroll") {
                setTimeout(() => {
                    EventBus.$emit("play-mobile-instream", muted);
                }, 300);
            }
            if (this.size.platform === "mobile" && this.size.format === "instream") {
                setTimeout(() => {
                    EventBus.$emit("play-mobile-instream", muted);
                }, 300);
            }
            if (this.size.platform === "instream" && this.size.format === "instream") {
                setTimeout(() => {
                    EventBus.$emit("play-desktop-instream", muted);
                }, 300);
            }
            if ((this.size.platform === "mobile" || this.size.format === "mobile") && !this.state.isMobile) {
                this.demopageRenderer = "external";
                // Share adunit with mobile bezel
                sessionStorage.setItem(
                    "currentSize",
                    JSON.stringify({
                        isCreativeConceptFlow: this.state.isCreativeConceptFlow,
                        creativeConceptFlowType: this.state.creativeConceptFlowType,
                        vdxFrameworkTemplateVersion: this.state.vdxFrameworkTemplateVersion,
                        ccRevisionVariation: this.state.ccRevisionVariation,
                        ccRevision: this.state.ccRevision,
                        isBokehMode: this.state.isBokehMode,
                        ...this.state.bundle.urls[sizeId],
                    })
                );
            } else {
                this.demopageRenderer = "internal";
            }

            Stats.send("size");
        },
        async showSingleDemopage(pathFragments) {
            let bezel = false;
            let bezelUrl = new URL(window.location.href);
            let adunit = {};
            if (pathFragments[0] === "demopage") {
                adunit = JSON.parse(sessionStorage.getItem("currentSize"));
                adunit.type = "expotask";
                if (adunit.format === "mobile") {
                    adunit.platform = "mobile";
                }
            } else {
                store.setMockId(pathFragments[1]);
                adunit = {
                    type: pathFragments[0] === "mock" ? "expotask" : pathFragments[0],
                    mediaDataID: pathFragments[1],
                    ad_size: pathFragments[2],
                };
                if (!pathFragments[3]) {
                    await store.fetchMockInfo();
                    adunit.platform = store.state.adunit.platform;
                    adunit.adFormatId = store.state.adunit.adFormatId;
                } else {
                    adunit.platform = pathFragments[3];
                }
                if (
                    adunit.platform === "mobile" &&
                    !this.state.isMobile &&
                    (document.referrer === "" || !window.location.href.includes(new URL(document.referrer).origin))
                ) {
                    bezel = true;
                    // Share adunit with mobile bezel
                    sessionStorage.setItem("currentSize", JSON.stringify(adunit));
                }
            }

            document.body.classList.add("loaded");
            this.singleSizeMode = true;
            store.setSingleSizeMode();
            if (bezel) {
                this.demopageRenderer = "external";
            } else {
                this.demopageRenderer = "internal";
                setTimeout(() => {
                    EventBus.$emit("play-mobile-instream", true);
                }, 300);
            }

            this.size = {
                type: adunit.type,
                id: adunit.mediaDataID,
                mediaDataID: adunit.mediaDataID,
                ad_size: adunit.ad_size,
                product: "",
                platform: adunit.platform,
                format: pathFragments.indexOf("inread") !== -1 ? "inread" : adunit.format,
                url: bezelUrl,
                json: adunit.json,
                adFormatId: adunit.adFormatId || null,
                ...(!bezel && adunit.platform === "mobile" && !this.state.isMobile
                    ? JSON.parse(sessionStorage.getItem("currentSize"))
                    : null),
            };
        },
        async getBundleInfo(hash = null) {
            Stats.closeSession();
            this.error = null;

            try {
                if (!hash) {
                    throw new Error("Bundle id is not present");
                }

                store.setHash(hash);
                const promises = [store.fetchBundleInfo(), store.isVPNConnection()];

                await Promise.all(promises);
            } catch (e) {
                if (e.message === "Failed to fetch" && store.state.isVDXStudioPreviewFlow) {
                    this.error = "turn on vpn";
                } else if (e.message === "adunit is not ready") {
                    this.error = e.message;
                } else {
                    this.error = true;
                }
            }

            document.body.classList.add("loaded");
            blockAdBlock.check();
            return !this.error;
        },
        showError(message = true) {
            this.error = message;
            document.body.classList.add("loaded");
            blockAdBlock.check();
        },
    },
});

async function route(reload) {
    const pathFragments = window.location.pathname.split("/");
    pathFragments.shift();
    if (pathFragments.length > 1 && ["mock", "demopage", "expo9", "flight", "expotask"].includes(pathFragments[0])) {
        App.showSingleDemopage(pathFragments);
    } else {
        let params = window.location.hash.substr(1).split("/");
        const urlSearchParams = new URLSearchParams(location.search);
        // Load bundle from external JSON
        if (urlSearchParams.has("json")) {
            if (!(await store.fetchBundleByJSONUrl(urlSearchParams.get("json")))) {
                App.showError();
                return;
            }
            store.setHash("");
            document.body.classList.add("loaded");
            blockAdBlock.check();
        } else {
            // If it's a new hash fetch bundle info
            // Client showcase url has only the bundle hash in VDXStudio preview flow
            if (pathFragments.length > 1 && (pathFragments[0] === "preview" || pathFragments[0] === "ccpreview")) {
                store.setVDXStudioPreviewFlow(true);
            } else {
                store.setVDXStudioPreviewFlow(false);
            }

            if (pathFragments.length > 1 && pathFragments[0] === "revision") {
                store.setRevision(parseInt(pathFragments[1]));
            } else {
                store.setRevision(null);
            }

            if (pathFragments.includes("cc")) {
                const ccIndex = pathFragments.findIndex((x) => x === "cc");
                const ccRevision = pathFragments[ccIndex + 1];
                const ccRevisionVariation = pathFragments[ccIndex + 2];

                store.setCreativeConceptInfoFromURL(ccRevision, ccRevisionVariation);
            } else if (pathFragments.length > 1 && pathFragments[0] === "ccpreview") {
                const mockId = pathFragments[1];
                const ccRevision = pathFragments[2];
                const ccRevisionVariation = pathFragments[3];
                const frameworkVersion = pathFragments[4];

                store.setCreativeConceptFrameworkVersion(frameworkVersion);
                store.setCreativeConceptMediaDataId(mockId);
                store.setMockId(mockId);
                store.setCreativeConceptFlow(true, "preview");
                store.setCreativeConcept(ccRevision, ccRevisionVariation);
            }

            if (params[0] !== store.state.hash) {
                if (!(await App.getBundleInfo(params[0]))) {
                    return;
                }

                console.log("Create session");
                if (window.location.pathname.indexOf("internal/notSharable") !== -1 && !store.state.isMobile) {
                    store.removeWarningsByTag("internal-bundle");
                    const saleskitUrl = store.getSaleskitUrl();
                    store.setWarning(
                        config.WARNINGS.INTERNAL_BUNDLE,
                        {
                            SALESKIT_URL: saleskitUrl,
                        },
                        "internal-bundle",
                        {
                            click: (e) => {
                                if (e.target.id === "warning_saleskit_url") {
                                    Stats.send("feature", {
                                        name: "REDIRECT_TO_SALESKIT",
                                        url: saleskitUrl,
                                    });
                                }
                            },
                        }
                    );
                }
                Stats.createSession({
                    hash: store.state.hash,
                    bundleId: store.state.bundle.bundle_id,
                    sizes: store.state.bundle.urls.map((size) => {
                        return { ad_size: size.ad_size, format: size.format, platform: size.platform };
                    }),
                    isMobile: store.state.isMobile,
                    isInternalUser: store.isInternalUser(),
                    byQRCode: urlSearchParams.has("qr"),
                });
                if (urlSearchParams.has("qr")) {
                    const newLocation = new URL(location);
                    newLocation.searchParams.delete("qr");
                    history.pushState({}, document.title, newLocation);
                }
            }
        }

        // If sizeId is not present redirect user to default sizeId
        if (!params[1]) {
            location.hash = params[0] + "/" + store.state.defaultSizeId;
            return;
        }

        // Redirect user if he tries to watch desktop adunit in mobile showcase
        if (store.state.bundle.urls[params[1]].platform !== "mobile" && store.state.isMobile) {
            location.hash = params[0] + "/" + store.state.defaultSizeId;
            return;
        }

        if (params.length > 2 && params[2] === "neurons") {
            store.state.bundle.urls[params[1] * 1].showHeatmap = true;
        }

        // If sizeId is present show demopage
        if (params[1] * 1 !== store.state.size || reload) {
            App.showBundleDemopage(params[1] * 1);
        }

        // Redirect to VDX Studio if url ended with /studio
        if (params.length > 2 && params[2] === "studio" && store.state.bundle.urls[params[1]]) {
            if (store.state.bundle.urls[params[1]] && store.state.bundle.urls[params[1]].is_created_in_vdx_studio) {
                if (params[0].length === 13) {
                    const vdxStudioUrl = config.VDX_STUDIO_API_URL + "/#/" + params[0].substring(0, 12);
                    Stats.send("feature", {
                        name: "REDIRECT_TO_VDX_STUDIO",
                        url: vdxStudioUrl,
                    });
                    location.href = vdxStudioUrl;
                } else {
                    Stats.send("feature", {
                        name: "REDIRECT_TO_VDX_STUDIO",
                        error: "SHORT_HASH",
                    });
                    alert("Short hashes are not supported");
                }
            } else {
                Stats.send("feature", {
                    name: "REDIRECT_TO_VDX_STUDIO",
                    error: "UNIT_WAS_NOT_GENERATED_BY_VDX_STUDIO",
                });
                alert("This unit wasn't generated by VDX Studio");
                history.back();
            }
        }

        //Redirect to Expotask Creative Request
        if (params.length > 2 && params[2] === "expotask" && store.state.bundle.urls[params[1]]) {
            if (store.state.bundle.urls[params[1]]._creative_request_id) {
                const expotaskUrl =
                    config.EXPOTASK_URL + "/request/info/" + store.state.bundle.urls[params[1]]._creative_request_id;
                Stats.send("feature", {
                    name: "REDIRECT_TO_EXPOTASK",
                    url: expotaskUrl,
                });
                location.href = expotaskUrl;
            } else if (store.state.isVDXStudioPreviewFlow) {
                Stats.send("feature", {
                    name: "REDIRECT_TO_EXPOTASK",
                    error: "PREVIEW_UNIT",
                });
                alert("This a preview unit, Publish it before viewing in Expotask");
                history.back();
            } else {
                Stats.send("feature", {
                    name: "REDIRECT_TO_EXPOTASK",
                    error: "EXPOTASK_URL_IS_NOT_AVAILABLE",
                });
                alert("Expotask URL is not available, please report this to support.vdxstudio@exponential.com");
                history.back();
            }
        }
    }
}

window.onhashchange = () => route();
window.showcase = {
    setPublisherPlayerSound(volume) {
        store.setPublisherPlayerSound(volume);
    },
};
