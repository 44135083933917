<template>
<div class="page">
    <header><h1></h1></header>
    <div class="row wrap">
        <div class="col w728">
            <div class="row">
                <div class="col ad-728x90" :class="[adunit.format]" ref="adunit">
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h4 v-fill="3"></h4>
                    <p v-fill="15"></p>
                    <p v-fill="50"></p>
                    <p img="1:1"></p>
                </div>
                <div class="col">
                    <h4 v-fill="3"></h4>
                    <p img="1:2"></p>
                    <p v-fill="15"></p>
                    <p v-fill="50"></p>
                    <p v-fill="45"></p>
                </div>
            </div>
        </div>
        <div class="col">
            <p v-fill="25"></p>
            <p img="2:1"></p>
            <p v-fill="45"></p>
        </div>
    </div>
</div>
</template>

<style lang="scss">
</style>

<script>
import TagsLoaderMixin from "../mixins/TagsLoader";

export default {
    name: "Desktop728x90",
    mixins: [TagsLoaderMixin]
}
</script>
