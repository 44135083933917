<template>
    <div v-if="state.vpnConnection" class="screenshots">
        <a @click="openDialog">Screenshots</a>
        <Teleport v-if="showDialog" to="body">
            <div class="screenshots-modal">
                <div class="fade"></div>
                <div class="dialog">
                    <div class="title">Screenshots</div>
                    <div class="mode-switch">
                        <button :class="{ active: mode === 'auto' }" @click="switchMode('auto')">Auto</button>
                        <button :class="{ active: mode === 'manual' }" @click="switchMode('manual')">Manual</button>
                    </div>
                    <div v-if="mode === 'auto'" class="auto-mode">
                        <div class="description">
                            Take a series of screenshots automatically for each ad size and each screen of the unit. The
                            process can take up to 5 - 10 minutes.
                            <div class="error">{{ error }}</div>
                        </div>
                        <div v-if="status === 'in progress'" class="progress-bar">
                            <div :style="{ width: this.progress + '%' }"></div>
                        </div>
                        <div v-if="status === 'done'" class="download">
                            <a :href="archiveUrl" download="screenshots.zip">Download ZIP</a>
                        </div>
                        <footer>
                            <button @click="generate" class="generate-button">
                                {{ status === "in progress" ? "In progress" : "Generate" }}
                            </button>
                            <button @click="close" class="close-button">Close</button>
                        </footer>
                    </div>
                    <div v-if="mode === 'manual'" class="manual-mode">
                        <div class="description">
                            Manually take screenshots for specific sizes and products. The browser will prompt a message
                            to share tab's contents. To take a screenshot please use button above the ad unit or key
                            combination CTRL+S.
                            <div class="error">{{ error }}</div>
                        </div>
                        <div v-if="archiveUrlManual && !archiving" class="download">
                            <a :href="archiveUrlManual" download="screenshots.zip">Download ZIP</a>
                        </div>
                        <footer>
                            <button v-if="!isCapturing" @click="startCapture" class="generate-button">
                                {{ archiving ? "In progress" : "Start" }}
                            </button>
                            <button v-if="isCapturing" @click="stopCapture" class="generate-button">Stop</button>
                            <button @click="close" class="close-button">Close</button>
                        </footer>
                    </div>
                </div>
            </div>
        </Teleport>
        <Teleport v-if="isCapturing" to="body">
            <div class="take-screenshot">
                <a href="" @click.prevent="takeScreenshot($event)">Take a screenshot</a>
            </div>
        </Teleport>
        <Teleport to="body">
            <div class="fade" id="screenshot-fade"></div>
        </Teleport>
    </div>
</template>
<style scoped>
.fade {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: #fff;
    z-index: 100000000000;
    display: none;
}

.screenshots a {
    display: block;
    height: 40px;
    padding: 0 0 0 75px;
    line-height: 40px;
    font-size: 12px;
    color: #9c9fa0;
    border-bottom: 1px solid #eeeeee;
}

.screenshots a:hover {
    color: #405de6;
}

.dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -300px;
    margin-top: -150px;
    width: 600px;
    min-height: 300px;
    background-color: #fff;
    z-index: 100000000000;
    box-shadow: 2px 2px 6px;
    padding: 10px 20px 70px;
    border-radius: 3px;
}

.dialog .description {
    padding-top: 10px;
    font-size: 16px;
}
.dialog .error {
    padding-top: 10px;
    color: crimson;
    font-size: 12px;
}

.dialog .title {
    font-size: 20px;
}

.dialog footer {
    position: absolute;
    bottom: 0px;
    height: 50px;
}

.dialog .progress-bar {
    border-radius: 5px;
    height: 10px;
    margin: 30px 0px;
    width: 100%;
    background-color: #ccc;
    overflow: hidden;
}

.dialog .progress-bar div {
    height: 10px;
    background-color: #405de6;
    transition: width ease-out 0.5s;
}

.dialog footer button {
    display: inline-block;
    margin: 0 66px;
    min-width: 150px;
    background-color: #fff;
    border: 1px solid;
    padding: 5px 0px;
    border-radius: 3px;
}

.dialog .download {
    padding-top: 20px;
    text-align: center;
}

.dialog .download a {
    display: inline-block;
    border: 1px solid;
    border-radius: 3px;
    background-color: #fff;
    padding: 5px 10px;
}

.dialog .mode-switch {
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 10px 0;
}

.dialog .mode-switch button {
    display: inline-block;
    background-color: #fff;
    border: 1px solid #405de6;
    width: 100px;
    height: 35px;
}
.dialog .mode-switch button.active {
    background-color: #405de6;
    color: #fff;
}

.dialog .mode-switch button:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-right: 0;
}

.dialog .mode-switch button:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.take-screenshot {
    position: fixed;
    top: 0px;
    left: 477px;
    z-index: 10000000000;
}
.take-screenshot a {
    border: 1px solid;
    padding: 8px 15px;
    background: #fff;
    display: inline-block;
    width: 971px;
    text-align: center;
    font-size: 22px;
    color: #405de6;
}
</style>
<script>
import { store } from "../store";
import Teleport from "./Teleport.vue";
import ScreenshotsService from "../services/ScreenshotsService.js";
import { getCombinedHash } from "../utils";

const ERROR_MESSAGES = {
    NOT_CANVAS: "This bundle was created in Muse, please make sure to use VDX Studio for the best results.",
    OTHER: "Something went wrong. Please contact developer team.",
};

const getBoundingClientRect = (element, override) => {
    console.log(element, element.getBoundingClientRect());
    const { x, y, width, height } = element.getBoundingClientRect();
    return { x, y, width, height, ...override };
};

export default {
    name: "NavigationOpenIn",
    components: { Teleport },
    data: function () {
        return {
            state: store.state,
            showDialog: false,
            progress: 0,
            status: "new",
            archiveUrl: "",
            mode: "auto",
            error: "",

            canvas: {},
            context: {},
            video: {},
            captureStream: {},
            isCapturing: false,
            postMessageListener: null,
            adunitState: "loading",
            adunitIframe: null,
            savingScreenshot: false,
            uploadScreenshotCount: 0,
            archiveUrlManual: "",
            archiving: false,
        };
    },
    watch: {
        "state.bundle": {
            handler() {
                this.getProgress();
            },
            immediate: true,
        },
        "state.size": {
            handler() {
                this.adunitState = "loading";
            },
            immediate: true,
        },
        notSupported: {
            handler() {
                if (this.notSupported) {
                    this.error = ERROR_MESSAGES.NOT_CANVAS;
                } else {
                    this.error = "";
                }
            },
            immediate: true,
        },
    },
    computed: {
        notSupported() {
            console.log(this.mode, this.state.bundle.flow);
            return this.mode === "auto" && this.state.bundle.flow !== "non-muse";
        },
        screenshotArchiveName() {
            if (this.state.bundle.name) {
                return this.state.bundle.name + "_screenshots";
            } else if (this.state.bundle.brand_name) {
                return this.state.bundle.brand_name + "_screenshots";
            } else {
                return "screenshots";
            }
        },
    },
    mounted: function () {
        this.postMessageListener = window.addEventListener("message", (e) => {
            if (e.type === "message") {
                try {
                    const message = e.data;
                    if (message.type === "currentState") {
                        console.log("%cChange current state to ", "color:green", e.data);
                        this.adunitState = message.data;
                        const allIframes = document.querySelectorAll("iframe");
                        allIframes.forEach((iframe) => {
                            if (iframe.contentWindow === e.source) {
                                this.adunitIframe = iframe;
                            }
                        });
                    }
                } catch (e) {
                    console.log(e);
                    return;
                }
            }
        });
    },
    methods: {
        openDialog() {
            this.showDialog = true;
        },

        switchMode(mode) {
            this.mode = mode;
        },

        async generate() {
            /**
             * @type {State}
             */
            const state = this.state;

            if (this.status === "in progress") {
                return;
            }
            const keys = {};
            const uniqueAdUnits = this.state.bundle.urls.filter((url) => {
                const key = url.product + url.ad_size;
                if (!keys[key]) {
                    keys[key] = true;
                    return true;
                }
                return false;
            });

            // we need to take screenshot of expandable mainunit only once per product
            // use this object to store expandable products that we've passed already to bundle json
            const expandableProducts = {};

            const bundle = {
                hash: this.state.bundle.hash,
                adUnits: uniqueAdUnits
                    .map((adUnit) => {
                        const size = {
                            id: adUnit._raw_i,
                            size: adUnit.ad_size,
                            product: adUnit.product
                                .toLowerCase()
                                .replace("connect", "")
                                .replace("vdxinstream", "vdxdesktopinstream"),
                        };
                        if (size.product.indexOf("expandable") !== -1 && expandableProducts[size.product]) {
                            size.settings = {
                                states: { mainunit: false },
                            };
                        } else {
                            expandableProducts[size.product] = true;
                        }
                        return size;
                    })
                    .filter((v) => v.size.indexOf("qrcodeexperience") === -1),
            };

            if (state.isCreativeConceptFlow) {
                bundle.ccRevision = state.ccRevision;
                bundle.ccRevisionVariation = state.ccRevisionVariation;
                bundle.projectHash = this.state.bundle.hash;
                bundle.hash = getCombinedHash(this.state);
            }

            this.status = "in progress";
            this.progress = 0;
            await ScreenshotsService.generateScreenshots(bundle);
            this.getProgress();
        },

        async getProgress() {
            if (!this.state.bundle.hash) {
                return;
            }

            const hash = this.state.isCreativeConceptFlow ? getCombinedHash(this.state) : this.state.bundle.hash;

            const response = await ScreenshotsService.getStatus(hash);
            if (response.data.status === "running") {
                this.status = "in progress";
                this.progress = parseInt(response.data.progress * 100);
                setTimeout(() => {
                    this.getProgress();
                }, 3000);
            } else if (response.data.status === "done") {
                this.status = "done";
                this.archiveUrl = ScreenshotsService.getArchiveUrl(hash, "auto", this.screenshotArchiveName);
            }
        },

        async startCapture() {
            const [adunitWidth, adunitHeight] = this.state.bundle.urls[this.state.size].ad_size.split("x");
            this.canvas = new OffscreenCanvas(
                adunitWidth * window.devicePixelRatio,
                adunitHeight * window.devicePixelRatio
            );
            this.context = this.canvas.getContext("2d");
            this.video = document.createElement("video");

            try {
                this.captureStream = await navigator.mediaDevices.getDisplayMedia({
                    video: {
                        width: {
                            ideal: window.innerWidth * window.devicePixelRatio,
                            max: window.innerWidth * window.devicePixelRatio,
                        },
                        height: {
                            ideal: window.innerHeight * window.devicePixelRatio,
                            max: window.innerHeight * window.devicePixelRatio,
                        },
                    },
                    preferCurrentTab: true,
                });
                this.video.srcObject = this.captureStream;
                this.isCapturing = true;
                this.captureStream.getTracks().forEach((track) =>
                    track.addEventListener("ended", () => {
                        this.stopCapture();
                    })
                );
                await this.video.play();

                this.canvas.width = adunitWidth * window.devicePixelRatio;
                this.canvas.height = adunitHeight * window.devicePixelRatio;
                this.close();
                document.onkeydown = (e) => {
                    if (e.ctrlKey && e.code === "KeyS") {
                        this.takeScreenshot();
                        return false;
                    }
                };
                document.body.onblur = (e) => {
                    console.log("BLUR");
                    setTimeout(() => {
                        console.log("Focus");
                        window.focus();
                    }, 50);
                };
            } catch (err) {
                this.error = ERROR_MESSAGES.OTHER;
                console.error("Error: " + err);
            }
        },

        async takeScreenshot() {
            if (this.savingScreenshot) {
                return;
            }
            this.savingScreenshot = true;
            document.getElementById("screenshot-fade").style.display = "block";
            setTimeout(() => {
                document.getElementById("screenshot-fade").style.display = "none";
                this.savingScreenshot = false;
            }, 300);

            const { product, ad_size } = this.state.bundle.urls[this.state.size];
            const [adunitWidth, adunitHeight] = this.state.bundle.urls[this.state.size].ad_size.split("x");

            // Determine ad unit bounds depends on product and state
            let bounds = { x: 0, y: 0, width: 0, height: 0 };
            if (
                this.state.bundle.urls[this.state.size].product?.toLowerCase().includes("desktopexpandable") &&
                this.adunitState !== "teaser"
            ) {
                // Desktop Expandable main unit screenshot
                bounds = getBoundingClientRect(this.adunitIframe, { width: 970, height: 546 });
            } else {
                if (this.state.bundle.urls[this.state.size].platform?.toLowerCase() === "mobile") {
                    // Mobile teaser & main unit
                    bounds = getBoundingClientRect(document.getElementById("device"), { width: 432, height: 840 });
                } else if (this.state.bundle.urls[this.state.size].platform === "instream") {
                    // Desktop Instream
                    bounds = getBoundingClientRect(document.getElementById("adunit"), { width: 731, height: 441 });
                } else if (
                    ["CTV", "OTT", "VDXConnectTV"].indexOf(this.state.bundle.urls[this.state.size].product) !== -1
                ) {
                    // CTV / OTT
                    bounds = getBoundingClientRect(document.querySelector(".page.instream"));
                } else {
                    // Desktop Inframe / Desktop Expandable teaser
                    bounds = getBoundingClientRect(store.state.adunitElement, {
                        width: adunitWidth,
                        height: adunitHeight,
                    });
                }
            }

            this.canvas.width = bounds.width * window.devicePixelRatio;
            this.canvas.height = bounds.height * window.devicePixelRatio;
            this.context.drawImage(
                this.video,
                bounds.x * window.devicePixelRatio,
                bounds.y * window.devicePixelRatio,
                bounds.width * window.devicePixelRatio,
                bounds.height * window.devicePixelRatio,
                0,
                0,
                bounds.width * window.devicePixelRatio,
                bounds.height * window.devicePixelRatio
            );

            this.canvas.convertToBlob().then(async (blob) => {
                this.uploadScreenshotCount++;
                try {
                    const hash = this.state.isCreativeConceptFlow
                        ? getCombinedHash(this.state)
                        : this.state.bundle.hash;
                    await ScreenshotsService.saveScreenshot(hash, blob, {
                        product: product.toLowerCase().replace("connect", ""),
                        size: ad_size,
                        name: (this.adunitState !== "loading" ? this.adunitState : "screenshot") + "_" + Date.now(),
                    });
                } finally {
                    this.uploadScreenshotCount--;
                    if (this.uploadScreenshotCount === 0 && this.archiving) {
                        await this.createManualArchive();
                    }
                }
            });
        },

        async stopCapture() {
            document.onkeydown = null;
            document.body.onblur = null;
            this.captureStream.getTracks().forEach((track) => track.stop());
            this.isCapturing = false;
            this.archiving = true;
            if (this.uploadScreenshotCount === 0) {
                await this.createManualArchive();
            }
        },

        async createManualArchive() {
            const hash = this.state.isCreativeConceptFlow ? getCombinedHash(this.state) : this.state.bundle.hash;

            await ScreenshotsService.createArchive(hash);
            this.archiveUrlManual = ScreenshotsService.getArchiveUrl(hash, "manual", this.screenshotArchiveName);
            this.archiving = false;
        },

        close() {
            this.showDialog = false;
        },
    },
};
</script>
