<template>
    <ul class="warning" :class="{top: error}" v-if="warnings.length">
        <li :class="'lvl-' + warning.level" v-for="warning in warnings" :key="warning.message">
            <span v-html="warning.message" v-on="warning.handlers ? { click: warning.handlers.click } : {}"></span>
            <button v-if="warning.level !== 'error'" type="button" class="close" @click="close(warning)">&times;</button>
        </li>
    </ul>
</template>
<style scoped>
    .warning {
        list-style: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        padding-left: 305px;
        line-height: 30px;
        background: #405DE6;
        color: #fff;
        text-align: center;
        z-index: 2147483646;
    }
    .warning.top {
        z-index: 2147483647;
        padding-left: 0;
    }
    .warning li.lvl-warning {
        background: #405DE6;
    }
    .warning li.lvl-error {
        background: #f30;
    }
    .warning li >>> a { color: #aaa; }
    .warning li >>> a:hover { color: #fff; }
    .warning .close { font-size: 30px; line-height: 28px; text-shadow: none; font-weight: 100; color: #fff; opacity: 1; padding-right: 10px; }
</style>
<script>
import { store } from '../store';

export default {
    name: 'Warning',
    props: {
        error: String
    },
    computed: {
        warnings() {
            return store.state.warnings;
        }
    },
    methods: {
        close(warning) {
            store.removeWarning(warning);
        }
    }

}
</script>