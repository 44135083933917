import config from '../../config';
import { E9Tags } from '../../entities/e9Tags';
import { store } from '../../store';
import canAutoplay from '../../../lib/can-autoplay.min.js';

export default {
    methods: {
        render() {
            if (this.adunit.previewJson) {
                // Render VDX Studio preview
                this.renderPreview();
            } else {
                // Render published adunit
                this.renderPublished();
            }
        },
        renderPreview() {
            const getProductInfo = () => {
                let mockId = this.adunit.mediaDataID;
                return {
                    size: this.adunit.previewJson.adSize,
                    mockId: mockId,
                    container: 'adunit'
                }
            };
            const createDivforinstream = (e) => {
                var i = parseInt(e.size.split("x")[0]),
                    t = parseInt(e.size.split("x")[1]),
                    a = this.$refs.video.parentNode;
                a.id = e.mockId, a.style.height = t, a.style.width = i, document.getElementById(e.container).appendChild(a);
            };
            const writeInstreamContent = (e) => {
                const e9 = new E9Tags(this.adunit);
                if (store.state.presentationMode) {
                    e9.enablePresentationMode();
                }
                if (!this.adunit.previewJson.baseUrl) {
                    Object.assign(this.adunit.previewJson, { baseUrl: config.VDX_STUDIO_API_URL });
                }
                const i = {
                    ...this.adunit.previewJson
                };
                if (i.teaser) {
                    i.teaser.baseUrl = this.adunit.previewJson.baseUrl;
                }
                i[e.size] = {
                    states: this.adunit.previewJson.states,
                    locale: this.adunit.previewJson.locale,
                    peelConfig: this.adunit.previewJson.peelConfig,
                    mobileTeaserVideo: this.adunit.previewJson.mobileTeaserVideo
                }; - 1 !== navigator.appVersion.indexOf("MSIE") || navigator.userAgent.indexOf("Opera");
                var t = window.document.getElementById(e.mockId);
                e9.setCustomTags({
                    mediaDataID: productInfo.mockId, 
                    nonFragmentedVideo: !0,
                    creativeBuilderCfg: i
                });
                t.e9 = e9.toObject();
                window.e9 = t.e9;
                return e9;
            };

            const productInfo = getProductInfo();
            createDivforinstream(productInfo);
            const e9 = writeInstreamContent(productInfo);
            this.setupInread(e9);
        },
        renderPublished() {
            const self = this;
            const e9 = new E9Tags(this.adunit);
            if (store.state.presentationMode) {
                e9.enablePresentationMode();
            }
            this.setupInread(e9);
        },
        setupInread(e9) {
            const self = this;
            window.e9 = e9.toObject();
            var vjs;
            var isInit = false;

            var divslot = this.$refs.videoContainer;
            var adunitEnd = false;
            var adStarted = false;
            var fired = false;
            var vjs;
            var visibleStatus = 0;
            var adReady = false;

            var autoplayAllowed = false;
            var wrapperDiv;

            function initPlayer() {
                vjs = videojs(self.$refs.video, {
                    inactivityTimeout: 60000,
                    autoplay: false,
                    adLabel: "",
                    showCountdown: false,
                    muted: true,
                    controlBar: {
                        children: [
                            'playToggle',
                            'timeDivider',
                            'progressControl',
                            'muteToggle'
                        ]
                    }
                });
                setupIMA(vjs);
            }

            function initAdDisplayContainer() {
                vjs.ima.initializeAdDisplayContainer();
                wrapperDiv.removeEventListener(startEvent, initAdDisplayContainer);
            }

            var startEvent = 'click';

            checkUnmutedAutoplaySupport();
            inView(fireVastTag);


            window.document.addEventListener("visibilitychange", function() {
                if (!fired) {
                    return;
                }
                if (adunitEnd) {
                    return;
                }
                setTimeout(function() {
                    if (document.hidden) {
                        if (!adunitEnd) {
                            vjs.ima.pauseAd();
                        } else {
                            vjs.pause();
                        }
                    } else {
                        if (visibleStatus > 50) {
                            if (!adunitEnd) vjs.ima.resumeAd();
                        }
                    }
                }.bind(this), 100)

            });

            function checkUnmutedAutoplaySupport() {
                canAutoplay
                    .video({
                        timeout: 500,
                        muted: false,
                        inline: true
                    })
                    .then(function(response) {
                        if (response.result === false) {
                            // Unmuted autoplay is not allowed.
                            checkMutedAutoplaySupport();
                        } else {
                            // Unmuted autoplay is allowed.
                            console.log('Unmuted play is allowed');
                            autoplayAllowed = true;
                            initPlayer();
                        }
                    })
            };

            function checkMutedAutoplaySupport() {
                canAutoplay
                    .video({
                        timeout: 500,
                        muted: true,
                        inline: true
                    })
                    .then(function(response) {
                        if (response.result === false) {
                            // Muted autoplay is not allowed.
                            autoplayAllowed = false;
                        } else {
                            // Muted autoplay is allowed.
                            autoplayAllowed = true;
                        }
                        initPlayer();
                    })
            }

            function resumeRequested() {
                adunitEnd = true;
                vjs.pause();
                var videoslot = self.$refs.video;
                videoslot.style.display = "none";
                divslot.style.height = "1px";
            };

            function adsManagerLoadedCallback() {
                if (visibleStatus > 1) {
                    vjs.play();
                }

                vjs.ima.addEventListener(google.ima.AdEvent.Type.LOADED, function() {
                    console.log("ad break ready");
                    if (visibleStatus > 50) {
                        vjs.ima.playAdBreak();
                    }
                    adReady = true;
                })


                vjs.ima.addEventListener(google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED.ALL_ADS_COMPLETED, function() {
                    resumeRequested();
                    vjs.controls(true);
                })

                vjs.ima.addEventListener(google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED, function() {
                    resumeRequested();
                });

                vjs.ima.addEventListener(google.ima.AdEvent.Type.STARTED, function() {
                    adStarted = true;
                    vjs.controls(false);
                });

                vjs.ima.addEventListener(google.ima.AdEvent.Type.ALL_ADS_COMPLETED, function() {
                    resumeRequested();
                });
            }

            function setupIMA(vjs) {
                if (!isInit) {
                    var options = {
                        timeout: 60000,
                        adLabel: "",
                        showCountdown: false,
                        vastLoadTimeout: 60000,
                        id: 'vid2',
                        vpaidMode: google.ima.ImaSdkSettings.VpaidMode.INSECURE,
                        showControlsForJSAds: false,
                        disableAdControls: false,
                        debug: true,
                        adsManagerLoadedCallback: adsManagerLoadedCallback.bind(this),
                        autoPlayAdBreaks: false,
                        adTagUrl: e9.getVPAIDUrl()
                    };
                    vjs.ima(options);

                    if (!autoplayAllowed) {
                        if (navigator.userAgent.match(/iPhone/i) ||
                            navigator.userAgent.match(/iPad/i) ||
                            navigator.userAgent.match(/Android/i)) {
                            startEvent = 'touchend';
                        }

                        wrapperDiv = this.$refs.video;
                        wrapperDiv.addEventListener(startEvent, initAdDisplayContainer);
                    }


                }
                isInit = true;
            }


            function inView(callback) {
                var ioOptions = {
                    threshold: [0.0, 0.01, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
                    rootMargin: '0px',
                };

                function ioHandler(entries) {
                    entries.forEach(function(entry) {
                        visibleStatus = (entry.intersectionRatio * 100);
                        callback.call(this, visibleStatus);
                    });
                }
                var observer = new IntersectionObserver(ioHandler, ioOptions);
                observer.observe(divslot);
            }

            function fireVastTag() {
                if (adunitEnd || visibleStatus < 1) {
                    return;
                }
                if (visibleStatus >= 1 && !fired) {
                    var width = divslot.getBoundingClientRect().width;
                    var height = width * (9 / 16);
                    divslot.style.height = height + 40 + "px";
                    fired = true;
                }
                if (fired && !adunitEnd) {
                    checkStatus();
                };

                function checkStatus() {
                    if (visibleStatus > 50) {
                        self.$refs['scroll-down-hint'].style.display = 'none';
                        if (!adunitEnd && adStarted === true) {
                            vjs.ima.resumeAd();
                        } else {
                            if (adunitEnd) {
                                vjs.play();
                            }
                            if (adReady) {
                                vjs.ima.playAdBreak();
                            }
                        }

                    } else {
                        self.$refs['scroll-down-hint'].style.display = 'block';

                        if (!adunitEnd) {
                            vjs.ima.pauseAd();
                            vjs.pause();
                        } else
                            vjs.pause();
                    }
                }

            }
        }
    }
}