<template>
    <div class="open-in">
        <a v-if="vdxStudioUrl" :href="vdxStudioUrl" target="_blank">Open in VDX Studio</a>
        <!-- <a v-if="expotaskUrl" :href="expotaskUrl" target="_blank">Open in Expotask</a> -->
        <!-- <a v-if="creativeConceptUrl" :href="creativeConceptUrl" target="_blank">Open in Creative Concept Widget</a> -->
        <a v-if="bundleDeepLink" :href="bundleDeepLink" target="_blank">Open in Expotask</a>
    </div>
</template>
<style scoped>
    .open-in a {
        display: block;
        height: 40px; 
        padding: 0 0 0 75px;
        line-height: 40px; 
        font-size: 12px; 
        color: #9c9fa0; 
        border-bottom: 1px solid #EEEEEE;
    }
    .open-in a:hover {
        color: #405de6;
    }
</style>
<script>
import { store } from '../store';
import config from './../config';

export default {
    name: 'NavigationOpenIn',
    data: function() {
        return {
            state: store.state,
            creativeConceptUrl: '',
            bundleDeepLink: '',
            vdxStudioUrl: '',
            expotaskUrl: ''
        }
    },
    watch: {
        'state.hash': {
            handler() {
                this.computeUrls()
            },
            immediate: true
        },
        'state.size': {
            handler() {
                this.computeUrls()
            },
            immediate: true
        },
        'state.bundle': {
            handler() {
                this.computeUrls()
            },
            immediate: true
        },
        'state.vpnConnection': {
            handler(v) {
                if (!v) {
                    this.expotaskUrl = false;
                    this.vdxStudioUrl = false;
                    this.creativeConceptUrl = false;
                    this.bundleDeepLink = false;
                }
            }
        }
    },
    methods: {
        computeUrls() {
            let creativeRequestId;
            if (!this.state.hash || !this.state.bundle.urls || !this.state.vpnConnection || this.state.size === null) {
                return false;
            }
            const size = this.state.bundle.urls[this.state.size]
            if (!size) {
                return false;
            }
            this.expotaskUrl = false;
            this.vdxStudioUrl = false;
            this.creativeConceptUrl = false;
            this.bundleDeepLink = false;
            switch (store.state.hash.length) {
                case 6:
                case 7:
                    creativeRequestId = size['_creative_request_id'] ?? size['creativeRequestId'];
                    if (creativeRequestId) {
                        this.expotaskUrl = this.getExpotaskUrl(creativeRequestId);
                    }
                    if (this.state.bundle.hash) {
                        this.vdxStudioUrl = this.getVDXStudioUrl(this.state.bundle.hash);
                    }
                    break;
                case 12:
                    creativeRequestId = size['_creative_request_id'] ?? size['creativeRequestId'];
                    if (creativeRequestId) {
                        this.expotaskUrl = this.getExpotaskUrl(creativeRequestId);
                    }
                    this.vdxStudioUrl = this.getVDXStudioUrl(store.state.hash);
                    break;
                case 13:
                    creativeRequestId = size['_creative_request_id'] ?? size['creativeRequestId'];
                    if (creativeRequestId) {
                        this.expotaskUrl = this.getExpotaskUrl(creativeRequestId);
                    }
                    this.vdxStudioUrl = this.getVDXStudioUrl(store.state.hash.substring(0, 12));
                    break;
            }

            if (this.state.isCreativeConceptFlow) {
                this.creativeConceptUrl = this.state.creativeConceptUrl;
                this.bundleDeepLink = this.state.bundleDeepLink;
            }
        },
        getExpotaskUrl(creativeRequestId) {
            return config.EXPOTASK_URL + '/request/info/' + creativeRequestId;
        },
        getVDXStudioUrl(hash) {
            return config.VDX_STUDIO_API_URL + '/#/' + hash;
        }
    }
}
</script>