<script>
import { store } from "../store";
import Stats from "../stats";
import Desktop970x250 from "../demopages/desktop/Desktop970x250";
import Desktop300x600 from "../demopages/desktop/Desktop300x600";
import Desktop300x250 from "../demopages/desktop/Desktop300x250";
import Desktop728x90 from "../demopages/desktop/Desktop728x90";
import Desktop160x600 from "../demopages/desktop/Desktop160x600";
import DesktopInstream from "../demopages/desktop/DesktopInstream";
import DesktopInread from "../demopages/desktop/DesktopInread";
import MobileInstream from "../demopages/mobile/MobileInstream";
import MobileInread from "../demopages/mobile/MobileInread";
import MobileDefault from "../demopages/mobile/MobileDefault";
import MobileTablet from "../demopages/mobile/MobileTablet";
import MobilePreroll from "../demopages/mobile/MobilePreroll";
import CTV from "../demopages/tv/CTV";
import OTT from "../demopages/ott/OTT";
import QRCodeExperience from "../demopages/tv/QRCodeExperience";

import "../demopages/styles.scss";

const sizesToDemopage = {
    desktop: {
        "970x250": Desktop970x250,
        "300x600": Desktop300x600,
        "300x250": Desktop300x250,
        "728x90": Desktop728x90,
        "160x600": Desktop160x600,
        "1x10": DesktopInstream,
        default: Desktop970x250,
    },
    mobile: {
        "1x10": MobileInstream,
        "728x90": MobileTablet,
        "768x90": MobileTablet,
        default: MobileDefault,
    },
    TV: {
        "1x10": CTV,
        qrcodeexperience: QRCodeExperience,
    },
    OTT: {
        "1x10": OTT,
    },
};

export default {
    name: "Demopage",
    props: {
        size: {
            type: Object,
            default: {},
        },
        topOffset: {
            type: Number,
        },
    },
    render(h) {
        if (this.adunit.size) {
            console.log("Render adunit", this.adunit);
            let component;
            let attrs = { key: this.componentForceUpdate };
            if (
                (this.adunit.platform === "desktop" || this.adunit.platform === "instream") &&
                this.adunit.format !== "mobile"
            ) {
                if (this.adunit.platform === "desktop" && this.adunit.format === "inread") {
                    component = DesktopInread;
                } else if (this.adunit.platform === "desktop" && this.adunit.format === "preroll") {
                    component = DesktopInstream;
                } else {
                    component = sizesToDemopage.desktop[this.adunit.size] || sizesToDemopage.desktop.default;
                }
            } else if (this.adunit.platform === "mobile" && this.adunit.format === "inread") {
                component = MobileInread;
            } else if (this.adunit.platform === "mobile" && this.adunit.format === "preroll") {
                component = MobilePreroll;
            } else {
                component = sizesToDemopage[this.adunit.platform][this.adunit.size] || sizesToDemopage.mobile.default;
            }
            if (this.state.isMobile) {
                attrs.style = "left: 0; margin: 0 auto; position: static;";
            }
            return h(component, {
                props: { adunit: this.adunit, topOffset: this.topOffset },
                attrs,
                nativeOn: {
                    mouseenter: this.onMouseEnter,
                },
                key: this.remountComponent,
                on: {
                    reload: this.reload,
                },
            });
        }
    },
    data() {
        return {
            adunit: {
                ccRevisionVariation: "",
                ccRevision: "",
                vdxFrameworkTemplateVersion: "",
                mediaDir: "",
                mediaDataID: 0,
                mediaID: 0,
                size: "",
                adformat: "",
                platform: "",
                format: "",
                previewJson: false,
            },
            is_mobile: true,
            state: store.state,
            remountComponent: 0,
        };
    },
    watch: {
        size: {
            handler(v) {
                this.navigate(v);
            },
            immediate: true,
        },
    },
    methods: {
        /**
         * @param {DemoPageSize} size
         */
        navigate(size) {
            if (size.json) {
                this.$set(this.adunit, "previewJson", size.json);
            } else {
                this.$set(this.adunit, "previewJson", false);
            }
            if (size.mediaDataID) {
                this.adunit.mediaDataID = size.mediaDataID;
            } else if (size.mockid) {
                this.adunit.mediaDataID = size.mockid;
            } else if (size.url && !size.json) {
                this.adunit.mediaDataID = size.url.split("mock/")[1].split("/")[0] * 1;
            }
            this.adunit.mediaDir = this.adunit.mediaDataID;
            this.adunit.size = size.ad_size;
            this.adunit.adformat = size.product;
            this.adunit.adFormatId = size.adFormatId;
            this.adunit.platform = size.platform;
            this.adunit.format = size.format;
            this.adunit.type = size.type || "expotask";
            this.adunit.id = size.id;
            this.adunit.config = { ...size.config };
            this.adunit.media = size.media;

            if (size.ccRevisionVariation) {
                this.adunit.ccRevisionVariation = size.ccRevisionVariation;
            } else if (store.state.ccRevisionVariation) {
                this.adunit.ccRevisionVariation = store.state.ccRevisionVariation;
            }

            if (size.ccRevision) {
                this.adunit.ccRevision = size.ccRevision;
            } else if (store.state.ccRevision) {
                this.adunit.ccRevision = store.state.ccRevision;
            }

            if (size.vdxFrameworkTemplateVersion) {
                this.adunit.vdxFrameworkTemplateVersion = size.vdxFrameworkTemplateVersion;
            } else if (store.state.vdxFrameworkTemplateVersion) {
                this.adunit.vdxFrameworkTemplateVersion = store.state.vdxFrameworkTemplateVersion;
            }

            if (size.mediaID) {
                this.adunit.mediaID = size.mediaID;
            }

            if (size.isBokehMode != null) {
                this.adunit.config.isBokehMode = size.isBokehMode;
            } else if (store.state.isBokehMode != null) {
                this.adunit.config.isBokehMode = store.state.isBokehMode;
            }

            if (size.tagparams) {
                this.adunit.tagparams = size.tagparams;
            } else {
                this.adunit.tagparams = undefined;
            }
            if (size.QRCodeExperience) {
                this.adunit.QRCodeExperience = size.QRCodeExperience;
            }
            if (size.frameTimestamp) {
                this.adunit.frameTimestamp = size.frameTimestamp;
            }
            if (size.showHeatmap) {
                this.adunit.heatmap = true;
            } else {
                this.adunit.heatmap = false;
            }
            if (size.heatmapVideoUrl) {
                this.adunit.heatmapVideoUrl = size.heatmapVideoUrl;
            }

            if (size.isCreativeConceptFlow) {
                this.adunit.ccRevision = size.ccRevision;
                this.adunit.ccRevisionVariation = size.ccRevisionVariation;
                this.adunit.vdxFrameworkTemplateVersion = size.vdxFrameworkTemplateVersion;
                store.setCreativeConceptFlow(true, size.creativeConceptFlowType);
            }

            this.remountDemopage();
        },
        reload() {
            this.remountDemopage();
        },
        remountDemopage() {
            this.remountComponent = !this.remountComponent;
        },
        onMouseEnter(event) {
            Stats.send("demopage-hover");
        },
    },
};
</script>
