<template>
    <ul class="tabs">
		<li 
			v-for="tab in tabs" 
			:key="tab"
			:class="{active: selected === tab.name}">
			<a :href="tab.link" @click="select(tab.name)">{{tab.title}}</a>
		</li>
    </ul>
</template>

<style scoped>
	.tabs {
		text-align: center;
		list-style: none;
		padding: 0;
		line-height: 40px;
		height: 40px;
		overflow: hidden;
		font-size: 12px;
		position: relative;
	}
	.tabs li {
		display: inline-block;
		position: relative;
		z-index: 0;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		margin: 0 0px;
		width: 137px;
	}
	.tabs a { color: #555; text-decoration: none; width: 100%; height:40px; display: inline-block; font-weight: 600; }
	.tabs li.active {
		background: #FFF;
		color: #333;
		z-index: 2;
		border-bottom-color: #FFF;
		box-shadow: 0px 3px 6px #0000004C
	}
	.tabs:before {
		position: absolute;
		content: " ";
		width: 100%;
		bottom: 0;
		left: 0;
		z-index: 1;
	}
	.tabs li.active:before,
	.tabs li.active:after {
		position: absolute;
		bottom: 0px;
		width: 7px;
		height: 7px;
		content: " ";
	}
	.tabs li:before {
		left: -7px;
		border-bottom-right-radius: 8px;
		border-width: 0 1px 1px 0;
		box-shadow: 2px 2px 0 #D1D1D1;
	}
	.tabs li:after {
		right: -7px;
		border-bottom-left-radius: 6px;
		border-width: 0 0 1px 1px;
		box-shadow: -2px 2px 0 #D1D1D1;
	}
	.tabs li.active:before { box-shadow: 2px 2px 0 #FFF; }
	.tabs li.active:after { box-shadow: -2px 2px 0 #FFF; }
</style>

<script>

export default {
    name: 'NavigationTabs',
	props: {
		tabs: {
			type: Array,
			default: []
		},
		selected: {
            type: String,
            default: null
        }
	},
    methods: {
        select(v) {
			this.$emit('select', v);
        }
    }
}
</script>