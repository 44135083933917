import config from "../config";
import BundleCacheService from "./BundleCacheService";

export default {
    /**
     * Get bundle info
     *
     * @param {string} hash
     * @param {string | null} ccRevision
     * @param {string | null} ccRevisionVariation
     * @returns {Promise<ExpoTaskBundleData>}
     */
    async getBundleInfo(hash, ccRevision = null, ccRevisionVariation = null) {
        let body = [
            "method_name=getdata_bundle_showcase",
            "hash=" + hash,
            ccRevision ? `revision_id=${ccRevision}` : null,
            ccRevisionVariation ? `revision_variation_id=${ccRevisionVariation}` : null,
        ]
            .filter((x) => x != null)
            .join("&");
        let expotaskRequest = new Request(config.EXPOTASK_DEMOPAGES_API_URL, {
            method: "POST",
            headers: { "content-type": "application/x-www-form-urlencoded" },
            body: body,
        });

        return fetch(expotaskRequest)
            .then((response) => response.json())
            .then((res) => {
                if (!res.status) {
                    throw new Error("Invalid Opportunity ID");
                }
                if (!res.data || !res.data.urls) {
                    throw new Error("No creatives for this Opportunity ID");
                }
                return BundleCacheService.middleware(hash, res.data);
            })
            .catch((e) => {
                return BundleCacheService.middleware(hash);
            });
    },
    /**
     * Get mock info
     *
     * @param {string} mockId
     * @returns {}
     */
    async getMockInfo(mockId) {
        const params = {
            method: "get_mockAdFormat",
            mockId: mockId,
        };
        return fetch(config.EXPOTASK_BUILDER_API_URL + "?" + new URLSearchParams(params).toString())
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((res) => {
                if (!res.status) {
                    throw new Error(res.msg);
                }
                return res.ad_format_id;
            })
            .catch((e) => {
                return null;
            });
    },
};
