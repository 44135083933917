import { E9Tags } from '../../entities/e9Tags';
import { store } from '../../store';
import canAutoplay from '../../../lib/can-autoplay.min.js';

export default {
    props: {
        adunit: {
            type: Object,
            default() {
                return {
                    mediaDataID: 0,
                    adformat: '',
                    size: '',
                    previewJson: false
                };
            }
        }
    },
    data() {
        return { 
            player: {
                adunit: null,
                heatmap: null
            }
        };
    },
    mounted() {
        canAutoplay
            .video({
                timeout: 500,
                muted: false,
                inline: true
            })
            .then((response) => {
                if (response.result === false) {
                    // Unmuted autoplay is not allowed.
                    this.render(true);
                } else {
                    // Unmuted autoplay is allowed.
                    this.render(false);
                }
            });
    },
    methods: {
        render(isMuted) {
            if (this.adunit.heatmap) {
                this.playHeatmap();
                return;
            }
            if (this.adunit.previewJson) {
                // Render VDX Studio preview
                this.renderPreview(isMuted, false);
            } else {
                // Render published adunit
                this.renderPublished(isMuted);
            }
        },
        renderPublished(isMuted) {
            const self = this;
            const e9 = new E9Tags(this.adunit);
            if (store.state.presentationMode) {
                e9.setCustomTags({
                    "tagparams": {
                        "renderingenv":"showcase",
                        "productcontrolconfig":{
                            "isofftabautoswitch": { "enabled": true }
                        }
                    }
                });
            }
            window.e9 = e9.toObject();

            var autoplayRequiresMute = false;
            var vjs;

            const initVideoJS = (vast) => {
                vjs = videojs(self.$refs.video, {
                    inactivityTimeout: 0,
                    autoplay: false,
                    muted: false,
                    controlBar: {   
                        children: [
                            'playToggle',
                            'timeDivider',
                            'progressControl',
                            'muteToggle'
                        ]
                    }
                }, () => {
                    // restart CTV adunit after video is ended
                    const videoElement = vjs.tech({IWillNotUseThisInPlugins: true}).el_;
                    videoElement.addEventListener('ended', () => this.$emit('reload'));
                });

                vjs.controls(true);
                setupIMA(vast);
            }

            function setupIMA(vast) {
                var options = {
                    id: 'vid2',
                    adLabel: "",
                    showControlsForJSAds: true,
                    vpaidMode: google.ima.ImaSdkSettings.VpaidMode.INSECURE,
                    showCountdown: false,
                    disableAdControls: false,
                    timeout: 60000,
                    vastLoadTimeout: 60000,
                    adsManagerLoadedCallback: adsManagerLoadedCallback.bind(this),
                    debug: true
                };
                
                if (vast) {
                    options.adsResponse = vast;
                } else {
                    options.adTagUrl = e9.getVPAIDUrl();
                }

                function adsManagerLoadedCallback() {
                    if (!autoplayRequiresMute) {
                        vjs.play();
                    }
                    
                    // Workaround to add seekbar for CTV adunit
                    const adVideo = document.querySelector('video[title=Advertisement]');
                    document.getElementById('vid2_ima-seek-bar-div').parentNode.addEventListener('click', e => {
                        const seekBarWidth = document.getElementById('vid2_ima-seek-bar-div').clientWidth;
                        adVideo.currentTime = e.offsetX / seekBarWidth * adVideo.duration;
                    });

                    vjs.ima.addEventListener(google.ima.AdEvent.Type.ALL_ADS_COMPLETED, function() {
                        vjs.controls(true);
                        vjs.play();
                    });

                    vjs.ima.addEventListener(google.ima.AdEvent.Type.COMPLETE, function() {
                        vjs.controls(true);
                        vjs.play();
                    })

                    vjs.ima.addEventListener(google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED, function() {
                        vjs.play();
                        vjs.controls(true);
                    });

                    vjs.ima.addEventListener(google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED, function() {
                        vjs.play();
                    });
                }
                vjs.ima(options);
            }
            
            autoplayRequiresMute = isMuted;
            initVideoJS();

            var initAdDisplayContainer = function() {
                vjs.ima.initializeAdDisplayContainer();
                wrapperDiv.removeEventListener(startEvent, initAdDisplayContainer);
            }

            var startEvent = 'click';
            if (navigator.userAgent.match(/iPhone/i) ||
                navigator.userAgent.match(/iPad/i) ||
                navigator.userAgent.match(/Android/i)) {
                startEvent = 'touchend';
            }

            var wrapperDiv = document.getElementById('vid2');
            wrapperDiv.addEventListener(startEvent, initAdDisplayContainer);
        },
        playHeatmap() {
            const playerHeatmap = videojs(this.$refs['heatmap-video'], {
                inactivityTimeout: 0,
                autoplay: true,
                muted: false,
                controls: true,
                controlBar: {   
                    children: [
                        'playToggle',
                        'timeDivider',
                        'progressControl',
                        'muteToggle'
                    ]
                }
            });
        }
    }
}