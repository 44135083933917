<template>
<div class="page instream ott">
    <div class="col ad-player">
        <video-js v-if="!adunit.heatmap" id="vid2" ref="video" webkit-playsinline playsinline class="video-js vjs-default-skin vjs-big-play-centered vjs-16-9 vjs-static-controls" controls disablePictureInPicture preload="auto" width="960px" height="540px" >
            <source src="../ott-content.mp4" type="video/mp4" >
            <p>Video Playback Not Supported</p>
        </video-js>
        <div class="heatmap-overlay" :class="{'video-overlap': adunit.heatmap}">
            <div class="title">
                AI-simulated user attention heatmap powered by
                <img src="../../../img/neurons-logo.svg" height="40" />
            </div>
            <video-js id="heatmap-video" ref="heatmap-video" webkit-playsinline playsinline loop  class="video-js vjs-default-skin vjs-16-9 vjs-static-controls vjs-big-play-centered" controls disablePictureInPicture preload="auto" width="960px" height="540px">
                <source :src="this.adunit.heatmapVideoUrl" type="video/mp4">
            </video-js>
            <div class="description">Attention is the first step to engaging customers. Using Neurons’ predictive AI technology, we’re able to analyze with 95% accuracy where consumers will focus their attention during the VDX.tv ad experience</div>
        </div>
    </div>
</div>
</template>
<style lang="scss">
    .heatmap-button {
        position: absolute;
        bottom: 0;
    }
    .heatmap-overlay {
        position: relative;
        display: none;
    }
    .heatmap-overlay.video-overlap {
        z-index: 10000;
        display: block;
    }

    .heatmap-overlay .title {
        position: absolute;
        top: -90px;
        font-size: 28px;
        font-family: Open Sans;
    } 
     .heatmap-overlay .title img{
        top: 8px;
        margin-left: 10px;
        position: absolute;
    }
    .heatmap-overlay .description { 
        position: absolute;
        left: 210px;
        bottom: -180px;
        width: 562px;
        font-size: 15px;
        color: #00000071;
        font-family: Open Sans;
    }
</style>

<script>
import CTVMixin from '../mixins/CTV';

export default {
    name: 'OTT',
    mixins: [CTVMixin]
}
</script>