<template>
<div id="device" v-bind:class="[device, size.format, 'show', 'expand']" v-if="!state.isMobile">
    <div v-bind:class="['iframe-state', iframe_state]">
        <div class="inner" v-if="!state.isMobile">
            <b>please wait...</b>
        </div>
    </div>
    <div class="iframe-wrap" v-if="!embedded">
        <iframe allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" allow="autoplay" ref="frame"
                v-if="iframe_display" v-bind:src="url" v-on:load="onLoad($event)"></iframe>
    </div>
</div>
</template>
<script>
import { store } from '../store';

export default {
    name: 'Bezel',
    props: {
        size: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            embedded: false,
            url: null,
            iframe_display: false,
            device: 'desktop',
            iframe_state: 'loading',
            pointerEvents: false,
            state: store.state
        }
    },
    watch: {
        size: {
            handler(v) {
                this.navigate(v);
            },
            immediate: true
        }
    },
    mounted() {
        this.onPostMessage= this.onPostMessage.bind(this);
        window.addEventListener('message', this.onPostMessage);
    },
    beforeDestroy() {
        window.init = false;
        window.removeEventListener('message', this.onPostMessage);
    },
    methods: {
        navigate: function (size) {
            this.iframe_display = false;
            this.iframe_state = 'loading';
            setTimeout(() => {
                if (size.platform === 'instream' && size.format === 'mobile') {
                    this.device = 'mobile';
                } else if (size.platform === 'mobile' && ['728x90', '768x90'].includes(size.ad_size)) {
                    this.device = 'tablet';
                } else {
                    this.device = size.platform;
                }
                this.url = size.url;
                this.iframe_display = true;
            }, 200);
        },
        reload: function() {
            this.iframe_state = 'loading';
            this.iframe_display = false;
            setTimeout(() => {
                this.iframe_display = true;
            })
        },
        onLoad(e) {
            this.iframe_state = 'ready';
        },
        onPostMessage(event) {
            const protocol = 'showcase://';
            if (typeof event.data === 'string' && event.data.startsWith(protocol)) {
                this.$refs.frame.contentWindow.postMessage(event.data)
            }
        }
    }
};
</script>