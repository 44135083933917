import WseClient from '../lib/wse/client';

let sessionId = sessionStorage.getItem('sessionId');

let WS_PROTOCOL = 'ws://';
if (location.protocol === 'https:') WS_PROTOCOL = 'wss://';

let WS_URL = WS_PROTOCOL + location.host;

const wse = new WseClient(WS_URL);

wse.on('open', () => console.log('connected'));
wse.on('message', function(event, data) {
    if (event === 'id' && !sessionId) {
        sessionId = data;
        sessionStorage.setItem('sessionId', data);
    }
});
wse.on('close', (code, reason) => console.log('close!', code, reason));

export { wse, sessionId };