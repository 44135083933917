<template>
    <nav
        id="app"
        v-bind:class="[
            tabs.length > 1 ? 'has-tabs' : '',
            expand ? 'expand' : 'collapsed',
            'show_' + show_nav,
            !has_sizes ? 'hello' : '',
            showSaleskitButton ? 'show-saleskit-button' : '',
            isHideNav ? 'hide' : 'show',
        ]"
    >
        <div id="hello" class="clearfix" v-if="is_init">
            <div>
                <img width="200" src="/img/vdx-tv-logo.svg" />
            </div>
            <div class="add">
                <p v-if="message === true">
                    <b>Oops! We can't seem to find the page you are looking for.</b>
                    No worries! Go ahead and explore our <a class="link" href="https://vdx.tv/">website</a> instead.
                </p>
                <p v-if="message === 'turn on vpn'" class="turn-vpn-message">
                    <b>Turn on VPN to view this page or contact</b>
                    <a href="mailto:support.vdxstudio@exponential.com">support.vdxstudio@exponential.com</a>
                </p>
                <p v-if="message === 'adunit is not ready'">
                    <br /><br />
                    <b>Please make sure to push this bundle to Ready for Review subphase.</b>
                </p>
            </div>
        </div>

        <div class="nav-buttons">
            <div class="vdx-logo"><img src="/img/vdx-tv-logo.svg" /></div>
            <navigation-tabs
                v-if="tabs.length > 1"
                :tabs="tabs"
                :selected="tab"
                @select="changeSolution"
            ></navigation-tabs>
            <button class="qrtoggle" v-on:click="qr_toggle()" v-if="has_sizes">
                <i class="i i-qrcode"></i> <img src="/img/vdx-tv-logo.svg" />
            </button>
            <transition name="fade">
                <button
                    class="toggle"
                    v-bind:class="'size-' + size"
                    v-on:click="nav_toggle()"
                    v-if="has_sizes && showHamburger"
                >
                    <div class="wrap">
                        <span>{{ expand ? "hide" : "" }}</span>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <b class="clickable"></b>
                </button>
            </transition>
            <span v-if="has_sizes && showHamburger">
                <svg
                    class="mobile-menu-hint"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="170"
                    height="34"
                    viewBox="0 0 435.536 84.095"
                >
                    <defs>
                        <filter id="Path_6" x="0" y="0" width="450.536" height="84.095" filterUnits="userSpaceOnUse">
                            <feOffset dy="5" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="3" result="blur" />
                            <feFlood flood-color="#050505" flood-opacity="0.161" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                        </filter>
                    </defs>
                    <g id="hint" transform="translate(-99.499 -895.113)">
                        <g transform="matrix(1, 0, 0, 1, 99.5, 895.11)" filter="url(#Path_6)">
                            <path
                                id="Path_6-2"
                                data-name="Path 6"
                                d="M-2474.033,554l-30.775,35.268,30.775,29.168,390.568.271V553.613Z"
                                transform="translate(2514.5 -549.11)"
                                fill="#405de6"
                                stroke="#707070"
                                stroke-width="1"
                            />
                        </g>
                        <text
                            id="click_to_pick_an_ad_format"
                            data-name="click to pick an ad format"
                            transform="translate(152 942)"
                            fill="#fff"
                            font-size="31"
                            font-family="Helvetica"
                        >
                            <tspan x="0" y="0">Tap to see additional sizes</tspan>
                        </text>
                    </g>
                </svg>
            </span>
        </div>

        <ul class="sizes" v-show="has_sizes && expand" ref="sizes">
            <li class="logo-container" v-if="has_sizes && show_nav === 'sizes' && logo_url">
                <div class="logo client-logo">
                    <img :src="logo_url" />
                </div>
            </li>
            <li class="platform instream" v-if="nav.TV">
                <span class="ico"><i class="i-instream"></i></span>
                <span class="platform-title">TV Experience</span>
                <ul>
                    <li
                        v-if="nav.TV"
                        v-bind:size-pop="'ctv'"
                        v-for="(c, i) in nav.TV"
                        v-bind:class="c_raw_i === c._raw_i ? 'active' : ''"
                    >
                        <a v-bind:href="'#' + hash + '/' + c._raw_i" v-on:click="navigateToSize(c._raw_i)"
                            >{{ c.name }}
                            <i
                                v-if="c.neurovision && state.bundle.urls[c._raw_i].neuronsReady"
                                class="i-eye-tracking toggle-heatmap"
                                :class="{ active: state.bundle.urls[c._raw_i].showHeatmap }"
                                @click.stop.prevent="toggleHeatmap(c._raw_i)"
                            ></i>
                            <div
                                v-if="c.neurovision && !state.bundle.urls[c._raw_i].neuronsReady"
                                class="spinner"
                            ></div>
                        </a>
                    </li>
                </ul>
            </li>

            <li class="platform instream" v-if="nav.instream || nav.OTT">
                <span class="ico"><i class="i-tv"></i></span>
                <span class="platform-title">Digital video</span>
                <ul>
                    <li
                        v-if="nav.instream"
                        v-bind:size-pop="'instream_' + c.format"
                        v-for="(c, i) in nav.instream"
                        v-bind:class="c_raw_i === c._raw_i ? 'active' : ''"
                    >
                        <a v-bind:href="'#' + hash + '/' + c._raw_i" v-on:click="navigateToSize(c._raw_i)"
                            >{{ c.name }}
                        </a>
                    </li>
                    <li
                        v-if="nav.OTT"
                        v-bind:size-pop="'ott'"
                        v-for="(c, i) in nav.OTT"
                        v-bind:class="c_raw_i === c._raw_i ? 'active' : ''"
                    >
                        <a v-bind:href="'#' + hash + '/' + c._raw_i" v-on:click="navigateToSize(c._raw_i)"
                            >{{ c.name }}
                            <i
                                v-if="c.neurovision && state.bundle.urls[c._raw_i].neuronsReady"
                                class="i-eye-tracking toggle-heatmap"
                                :class="{ active: state.bundle.urls[c._raw_i].showHeatmap }"
                                @click.stop.prevent="toggleHeatmap(c._raw_i)"
                            ></i>
                            <div
                                v-if="c.neurovision && !state.bundle.urls[c._raw_i].neuronsReady"
                                class="spinner"
                            ></div>
                        </a>
                    </li>
                </ul>
            </li>

            <li class="platform desktop" v-if="nav.desktop">
                <span class="ico"><i class="i-desktop"></i></span>
                <span class="platform-title">in-page desktop</span>
                <ul>
                    <li v-if="nav.desktop.inframe" class="group"><i class="i-inframe"></i> inframe</li>
                    <li
                        v-if="nav.desktop.inframe"
                        v-bind:size-pop="'desktop_' + c.ad_size"
                        v-for="c in nav.desktop.inframe"
                        v-bind:class="c_raw_i === c._raw_i ? 'active' : ''"
                    >
                        <a v-bind:href="'#' + hash + '/' + c._raw_i" v-on:click="navigateToSize(c._raw_i)">{{
                            c.name
                        }}</a>
                    </li>

                    <li v-if="nav.desktop.expandable" class="group"><i class="i-expandable"></i> expandable</li>
                    <li
                        v-if="nav.desktop.expandable"
                        v-bind:size-pop="'desktop_' + c.ad_size"
                        v-for="c in nav.desktop.expandable"
                        v-bind:class="c_raw_i === c._raw_i ? 'active' : ''"
                    >
                        <a v-bind:href="'#' + hash + '/' + c._raw_i" v-on:click="navigateToSize(c._raw_i)">{{
                            c.name
                        }}</a>
                    </li>

                    <li v-if="nav.desktop.inread" class="group"><i class="i-inread"></i></li>
                    <li
                        v-if="nav.desktop.inread"
                        v-bind:size-pop="'desktop_inread'"
                        v-for="c in nav.desktop.inread"
                        v-bind:class="c_raw_i === c._raw_i ? 'active' : ''"
                    >
                        <a
                            v-bind:href="'#' + hash + '/' + c._raw_i"
                            v-on:click="navigateToSize(c._raw_i)"
                            style="text-transform: uppercase"
                            >{{ c.name }}</a
                        >
                    </li>
                </ul>
            </li>

            <li class="platform mobile" v-if="nav.mobile">
                <span class="ico"><i class="i-mobile"></i></span>
                <span class="platform-title">in-page mobile</span>
                <ul>
                    <li v-if="nav.mobile.instream" class="group"><i class="i-instream"></i> Digital video</li>
                    <li
                        v-if="nav.mobile.instream"
                        v-bind:size-pop="'instream_mobile'"
                        v-for="c in nav.mobile.instream"
                        v-bind:class="c_raw_i === c._raw_i ? 'active' : ''"
                    >
                        <a v-bind:href="'#' + hash + '/' + c._raw_i" v-on:click="navigateToSize(c._raw_i)">{{
                            c.name
                        }}</a>
                    </li>

                    <li v-if="nav.mobile.inframe" class="group"><i class="i-inframe"></i> inframe</li>
                    <li
                        v-if="nav.mobile.inframe"
                        v-bind:size-pop="'mobile_' + c.ad_size"
                        v-for="c in nav.mobile.inframe"
                        v-bind:class="c_raw_i === c._raw_i ? 'active' : ''"
                    >
                        <a v-bind:href="'#' + hash + '/' + c._raw_i" v-on:click="navigateToSize(c._raw_i)">{{
                            c.name
                        }}</a>
                    </li>

                    <li v-if="nav.mobile.expandable" class="group"><i class="i-expandable"></i> expandable</li>
                    <li
                        v-if="nav.mobile.expandable"
                        v-bind:size-pop="'mobile_' + c.ad_size"
                        v-for="c in nav.mobile.expandable"
                        v-bind:class="c_raw_i === c._raw_i ? 'active' : ''"
                    >
                        <a v-bind:href="'#' + hash + '/' + c._raw_i" v-on:click="navigateToSize(c._raw_i)">{{
                            c.name
                        }}</a>
                    </li>

                    <li v-if="nav.mobile.inread" class="group"><i v-if="!this.state.isMobile" class="i-inread"></i></li>
                    <li
                        v-if="nav.mobile.inread"
                        v-bind:size-pop="'mobile_300x600'"
                        v-for="c in nav.mobile.inread"
                        v-bind:class="c_raw_i === c._raw_i ? 'active' : ''"
                    >
                        <a
                            v-bind:href="'#' + hash + '/' + c._raw_i"
                            v-on:click="navigateToSize(c._raw_i)"
                            style="text-transform: uppercase"
                            >{{ c.name }}</a
                        >
                    </li>

                    <li v-if="nav.mobile.adhesion" class="group">
                        <i class="i-adhesion"
                            ><span class="path1"></span><span class="path2"></span><span class="path3"></span
                        ></i>
                        adhesion
                    </li>
                    <li
                        v-if="nav.mobile.adhesion"
                        v-bind:size-pop="'mobile_' + c.ad_size"
                        v-for="c in nav.mobile.adhesion"
                        v-bind:class="c_raw_i === c._raw_i ? 'active' : ''"
                    >
                        <a v-bind:href="'#' + hash + '/' + c._raw_i" v-on:click="navigateToSize(c._raw_i)">{{
                            c.name
                        }}</a>
                    </li>
                </ul>
            </li>

            <li class="qr" v-if="!this.state.isMobile && state.hasMobileAdunits">
                To view on your device:<br />
                - Open camera app<br />
                - Hold the device’s camera up to the QR code<br />
                <div v-html="qrcode"></div>
            </li>

            <li class="mobile-hint">
                <div class="desktop-experience-hint" v-if="has_sizes">
                    <img src="/img/desktop-icon.png" />
                    <div class="hint">
                        Open this url on your computer for desktop ad experience
                        <a v-bind:href="'/#' + hash" target="top">{{ href + "/#" + hash }}</a>
                    </div>
                </div>
            </li>
        </ul>
        <div class="nav-scroll-hint" v-if="showScrollArrow && !is_init">
            <div class="nav-arrow" v-if="showScrollArrow"></div>
            <span>Scroll Down</span>
        </div>
        <div class="open-in-saleskit" v-if="showSaleskitButton">
            <button v-on:click="openSaleskit">Open in Saleskit</button>
        </div>
        <div v-bind:class="['qrcode', qr_show ? 'show' : '']" v-if="has_sizes && show_nav === 'sizes'">
            <button v-on:click="qr_toggle(false)"><i class="i i-chevron-left"></i> back</button>

            <div class="qr" v-html="qrcode"></div>
            <ul class="hints">
                <li><img src="/img/camera-icon.png" />Open the Camera app</li>
                <li><img src="/img/qrcode-icon.png" />Scan the QR Code</li>
                <li><img src="/img/phone-icon.png" />Reveal the mobile ad experience</li>
            </ul>
        </div>
        <div class="dot-menu-container">
            <div :class="{ opened: dotMenuOpened }" class="dot-menu">
                <navigation-screenshots></navigation-screenshots>
                <navigation-open-in></navigation-open-in>
                <presentation-mode v-if="showPresentationModeButton"></presentation-mode>
                <NavigationBokehMode @changed="changeBokehMode" v-if="showBokehModeButton" />
            </div>
        </div>
        <div class="copy">
            VDX.tv. All rights reserved &copy; {{ copyrightYear }}
            <div
                v-if="has_sizes && isPublishMode"
                :class="{ opened: dotMenuOpened }"
                class="dot-menu-button"
                @click="dotMenuOpened = !dotMenuOpened"
            >
                <span></span>
            </div>
        </div>
    </nav>
</template>
<style scoped lang="scss">
$dot-menu-size: 30px;
$dot-menu-dot-size: 4px;

$dot-menu-dot-color: #405de6;

.dot-menu-container {
    position: absolute;
    bottom: 41px;
    width: 100%;
}
.dot-menu {
    display: none;
    width: 100%;
    border-left: 1px solid #b3b8bd;
    border-right: 1px solid #b3b8bd;
    border-top: 1px solid #b3b8bd;
    background: #fff;
}
.dot-menu.opened {
    display: block;
}

.dot-menu-button {
    display: block;
    float: right;
    width: $dot-menu-size;
    height: $dot-menu-size;
    margin: 6px auto 0;
    transition: background-color 0.2s linear;
    cursor: pointer;
    transition: all 0.5s;
    transform: rotate(0deg);
}

.dot-menu-button {
    text-indent: -9999em;
}

.dot-menu-button:before,
.dot-menu-button:after,
.dot-menu-button span:before {
    content: "";
    position: absolute;
    left: calc($dot-menu-size / 2) - calc($dot-menu-dot-size / 2);
    width: $dot-menu-dot-size;
    height: $dot-menu-dot-size;
    border-radius: $dot-menu-dot-size;
    background-color: $dot-menu-dot-color;
    transition: 0.4s;
}

.dot-menu-button:before {
    top: (calc($dot-menu-size / 2) - calc($dot-menu-dot-size / 2)) + ($dot-menu-dot-size * 1.5);
}

.dot-menu-button:after {
    top: (calc($dot-menu-size / 2) - calc($dot-menu-dot-size / 2)) - ($dot-menu-dot-size * 1.5);
}

.dot-menu-button span:before {
    top: (calc($dot-menu-size / 2) - calc($dot-menu-dot-size / 2));
}
.dot-menu-button.opened {
    transform: rotate(90deg);
}
.hide {
    display: none !important;
}
</style>
<script>
import config from "./../config";
import { store } from "../store";
import QRCode from "qrcode-svg";
import Stats from "../stats";
import PresentationMode from "./PresentationMode";
import NavigationTabs from "./NavigationTabs.vue";
import NavigationBokehMode from "./NavigationBokehMode.vue";
import NavigationOpenIn from "./NavigationOpenIn.vue";
import NavigationScreenshots from "./NavigationScreenshots.vue";

const SOLUTION_MAP = {
    "VDX TV Magnify": {
        title: "TV Magnify",
    },
    "VDX Video Magnify": {
        title: "Video Magnify",
    },
};

export default {
    name: "Navigation",
    components: { PresentationMode, NavigationTabs, NavigationBokehMode, NavigationOpenIn, NavigationScreenshots },
    props: {
        bundle: {
            type: Object,
            default: {},
        },
        error: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            qrcode: "",
            dotMenuOpened: false,
            cacheHovered: false,
            qr_show: false,
            is_init: true,
            expand: !store.state.isMobile,
            show_nav: "sizes",
            has_sizes: true,
            isPublishMode: true,
            message: "",
            size: null,
            logo_url: "",
            name: "no name",
            preview: null,
            nav: {},
            creativeMainUnitState: false,
            hamburger: true,
            href: window.location.origin,
            showScrollArrow: false,
            teaserContainer: false,
            showNavigation: false,
            state: store.state,
        };
    },
    computed: {
        showHamburger: function () {
            return !this.creativeMainUnitState && this.hamburger;
        },
        hash() {
            return this.state.hash;
        },
        c_raw_i() {
            return this.state.size;
        },
        isFromVDXStudio() {
            return this.state.isVDXStudioPreviewFlow;
        },
        showSaleskitButton() {
            return store.creativeIsFromSaleskit() && store.isInternalUser() && !this.state.isMobile;
        },
        showPresentationModeButton() {
            return !this.state.isMobile && !this.is_init && this.hash;
        },
        showBokehModeButton() {
            return this.isFromVDXStudio || this.bundle.is_se;
        },
        tab() {
            return (this.state.size !== null && this.state.bundle?.urls[this.state.size]?.solution) || "VDX TV Magnify";
        },
        tabs() {
            if (this.state.bundle.urls && this.state.groups) {
                return Array.from(this.state.groups).map((group) => {
                    return {
                        name: group,
                        title: SOLUTION_MAP[group]?.title,
                        link: "#" + this.hash + "/" + this.findDefaultSizeIdBySolution(group),
                    };
                });
            } else {
                return false;
            }
        },
        isHideNav() {
            return (this.nav.mobile && this.nav.mobile.preroll) || (this.nav.desktop && this.nav.desktop.preroll);
        },
        copyrightYear() {
            return new Date().getFullYear();
        },
    },
    mounted: function () {
        this.$refs.sizes.addEventListener("scroll", (e) => {
            this.calculateNavSizesScroll();
        });
        window.addEventListener("resize", (e) => {
            this.calculateNavSizesScroll();
        });
        setInterval(() => {
            if (!this.showNavigation) return;
            this.checkMenuAvailability();
        }, 200);
    },
    updated: function () {
        if (!this.state.isMobile) {
            this.calculateNavSizesScroll();
        }
    },
    watch: {
        bundle: {
            handler() {
                this.init();
            },
        },
        tab: {
            handler() {
                this.init();
            },
        },
        error(val) {
            console.log("Error: ", val);
            if (val) {
                this.show_nav = "hello";
                this.expand = true;
                this.message = val;
                this.is_init = true;
                this.has_sizes = false;
            } else {
                this.show_nav = "sizes";
                this.has_sizes = true;
            }
        },
        "state.isMobile": {
            handler(v) {
                if (!v) {
                    this.expand = true;
                }
            },
            immediate: false,
        },
    },
    methods: {
        init: function () {
            this.showNavigation = true;
            this.logo_url = this.bundle.logo_url;
            this.name = this.bundle.name;
            this.isPublishMode = !this.state.isVDXStudioPreviewFlow;

            let adunits = JSON.parse(JSON.stringify(this.bundle.urls));
            this.nav = {};

            this.generateQRCode();

            for (let i = 0; i < adunits.length; i++) {
                if (this.tabs && adunits[i].solution !== this.tab) {
                    continue;
                }

                let format = [];
                const adunit = adunits[i];

                this.has_sizes = true;

                if (adunit.platform === "mobile" && adunit.format === "instream") {
                    if (this.state.isMobile) {
                        adunit.name = "In Stream";
                        adunit.order = 1;
                    } else {
                        adunit.format = "mobile";
                        adunit.platform = "instream";
                        adunit.name = "Mobile Instream";
                        adunit.order = 10;
                    }
                }
                if (
                    adunits[i].platform === "instream" ||
                    adunits[i].platform === "TV" ||
                    adunits[i].platform === "OTT"
                ) {
                    if (!this.nav[adunits[i].platform]) this.nav[adunits[i].platform] = [];
                    format = this.nav[adunits[i].platform];
                } else {
                    if (!this.nav[adunits[i].platform]) this.nav[adunits[i].platform] = {};
                    if (!this.nav[adunits[i].platform][adunits[i].format])
                        this.nav[adunits[i].platform][adunits[i].format] = [];
                    format = this.nav[adunits[i].platform][adunits[i].format];
                }

                format.push(adunits[i]);

                if (adunits[i].neurovision && adunits[i].hash) {
                    this.checkNeuronsStatus(adunits[i]);
                }

                format.sort((a, b) => {
                    if (a.order < b.order) return -1;
                    if (a.order > b.order) return 1;
                    return a.name > b.name ? 1 : -1;
                });
            }

            this.is_init = false;
            if (this.state.isMobile) {
                this.teaserContainer = null;
            }
        },
        checkNeuronsStatus(adunit) {
            const checkStatus = async () => {
                const hashWithCCInfo = [this.state.hash, this.state.ccRevision, this.state.ccRevisionVariation];
                const hash = this.state.isCreativeConceptFlow ? hashWithCCInfo.join("/") : adunit.hash;
                /**
                 * @type {BundleUrl}
                 */
                const url = this.state.bundle.urls[adunit._raw_i];
                const neuronsData = await store.getNeuronsData(hash);

                if ("status" in neuronsData && neuronsData.status === "ready") {
                    url.neuronsReady = true;
                    url.heatmapVideoUrl = neuronsData.heatmapVideoUrl;
                } else if ("CTV" in neuronsData && neuronsData.CTV.status === "ready" && url.product === "CTV") {
                    url.neuronsReady = true;
                    url.heatmapVideoUrl = neuronsData.CTV.heatmapVideoUrl;
                } else if ("OTT" in neuronsData && neuronsData.OTT.status === "ready" && url.product === "OTT") {
                    url.neuronsReady = true;
                    url.heatmapVideoUrl = neuronsData.OTT.heatmapVideoUrl;
                } else {
                    setTimeout(checkStatus, 5000);
                }
            };
            checkStatus();
        },
        generateQRCode() {
            const url = new URL(window.location.href);
            url.searchParams.set("qr", true);
            // Check if showcase loads custom bundle via json link
            // If yes encode the link to avoid an issue of non encoded links on mobile devices
            const searchParams = new URLSearchParams(url.search);
            if (searchParams.has("json")) {
                url.search = searchParams.toString();
            }
            let qrcode = new QRCode(url.href);
            this.qrcode = qrcode.svg();
        },
        qr_toggle(qr_show = null) {
            if (qr_show === null) {
                this.qr_show = !this.qr_show;
            } else {
                this.qr_show = !!qr_show;
            }
        },
        nav_toggle: function (expand = null) {
            let state;
            const self = this;

            if (expand === null) {
                state = !this.expand;
            } else {
                state = !!expand;
            }
            setTimeout(function () {
                self.expand = state;
                if (!self.expand && self.has_sizes) self.show_nav = "sizes";
            }, 10);
        },
        navigateToSize(sizeId) {
            if (this.state.bundle.urls[this.c_raw_i].showHeatmap) {
                this.state.bundle.urls[this.c_raw_i].showHeatmap = false;
            }
            if (this.state.isMobile) {
                this.nav_toggle();
            }
            this.teaserContainer = null;
            this.$emit("size-changed", sizeId, false);
        },
        calculateNavSizesScroll() {
            if (this.state.isMobile) {
                this.showScrollArrow = false;
                return;
            }
            if (!this.$refs.sizes) {
                return;
            }
            if (this.$refs.sizes.clientHeight < this.$refs.sizes.scrollHeight) {
                this.showScrollArrow = !(
                    this.$refs.sizes.scrollTop + this.$refs.sizes.clientHeight >=
                    this.$refs.sizes.scrollHeight
                );
            } else {
                this.showScrollArrow = false;
            }
        },
        checkMenuAvailability() {
            if (this.state.isMobile) {
                // check if adunit isn't adhesion
                const menuBound = { top: 18, height: 52 };
                if (!this.teaserContainer) {
                    this.teaserContainer = document.querySelector("#adunit");
                } else {
                    const teaserBound = this.teaserContainer.getBoundingClientRect();
                    if (
                        ((menuBound.top < teaserBound.top + teaserBound.height &&
                            menuBound.top + menuBound.height > teaserBound.top) ||
                            false) &&
                        !this.expand
                    ) {
                        this.hamburger = false;
                    } else {
                        this.hamburger = true;
                    }
                }

                const navContainer = document.getElementById("app");
                if (document.body.children[document.body.children.length - 1] !== navContainer) {
                    document.body.appendChild(navContainer);
                }
            }
        },
        openSaleskit() {
            const saleskitUrl = store.getSaleskitUrl();
            Stats.send("feature", {
                name: "REDIRECT_TO_SALESKIT",
                url: saleskitUrl,
            });
            window.open(saleskitUrl);
        },
        changeSolution(v) {
            this.$refs.sizes.scrollTop = 0;
            this.navigateToSize(this.findDefaultSizeIdBySolution(v));
        },
        changeBokehMode() {
            this.navigateToSize(this.state.size);
        },
        toggleHeatmap(i) {
            if (i !== this.c_raw_i && this.state.bundle.urls[this.c_raw_i].showHeatmap) {
                this.state.bundle.urls[this.c_raw_i].showHeatmap = false;
            }
            this.state.bundle.urls[i].showHeatmap = !this.state.bundle.urls[i].showHeatmap;
            this.teaserContainer = null;
            this.$emit("size-changed", i, false);
            const url = `#${this.hash}/${this.c_raw_i}`;
            if (this.state.bundle.urls[i].showHeatmap) {
                location.hash = url + "/neurons";
            } else {
                location.hash = url;
            }
        },
        findDefaultSizeIdBySolution(solution) {
            let currentSizeOrder = Number.MAX_SAFE_INTEGER;
            let defaultSizeId = 0;
            this.state.bundle.urls.forEach((size) => {
                if (!(this.state.isMobile && size.platform !== "mobile") && size.solution === solution) {
                    let defaultSizeOrder =
                        size.defaultSizeOrder ||
                        config.DEFAULT_SIZE_ORDER[size.platform + "." + size.format + "." + size.ad_size];
                    defaultSizeOrder = defaultSizeOrder || config.DEFAULT_SIZE_ORDER[size.platform + "." + size.format];
                    defaultSizeOrder = defaultSizeOrder || config.DEFAULT_SIZE_ORDER[size.platform];
                    defaultSizeOrder = defaultSizeOrder || Number.MAX_SAFE_INTEGER;
                    if (defaultSizeOrder <= currentSizeOrder) {
                        currentSizeOrder = defaultSizeOrder;
                        defaultSizeId = size._raw_i;
                    }
                }
            });
            return defaultSizeId;
        },
    },
};
</script>
