<template>
<div class="page instream">
    <header><h1></h1></header>
    <div class="row desktop-instream">
        <div class="col ad-player">
            <div id="adunit">
                <div>
                    <video-js id="vid2" ref="video" webkit-playsinline playsinline class="video-js vjs-default-skin vjs-big-play-centered vjs-16-9 vjs-static-controls" disablePictureInPicture preload="auto" >
                        <source src="../instreamcontent.mp4" type="video/mp4" >
                        <p>Video Playback Not Supported</p>
                    </video-js>
                </div>
            </div>
            <div class="content">
                <div>
                    <div class="video-description">
                        <span class="pic"></span>
                        <div>
                            <h4 class="title"></h4>
                            <span class="description" v-fill="10"></span>
                        </div>
                    </div>
                    <ul class="comments">
                        <li>
                            <span class="pic"></span>
                            <span class="message" v-fill="10"></span>
                        </li>
                        <li>
                            <span class="pic"></span>
                            <span class="message" v-fill="10"></span>
                        </li>
                    </ul>
                </div>
                <div class="actions">
                    <svg id="svg-like" data-name="svg-like" xmlns="http://www.w3.org/2000/svg" width="51.282" height="48.802" viewBox="0 0 51.282 48.802">
                        <path id="Path_98" data-name="Path 98" d="M599.765,625.258c-2.865-2.531-12.464-1.15-12.464-1.15a5.643,5.643,0,0,0-2.436.843,6.256,6.256,0,0,0-1.687,2.061v20.239a8.037,8.037,0,0,0,.99,2.309A5.079,5.079,0,0,0,585.8,651h9.183a7.618,7.618,0,0,0,2.193-.987,10.524,10.524,0,0,0,1.93-1.824S602.629,627.794,599.765,625.258Z" transform="translate(-583.178 -602.197)" fill="#ecf0f3"/>
                        <path id="Path_99" data-name="Path 99" d="M598.7,625.891l1.9-.281s6.262-8.134,6.908-13.815-.428-4.9,0-7.263.694-1.872,1.711-2.2a5.207,5.207,0,0,1,2.356,0,6,6,0,0,1,3.163,2.2c1.3,1.767,1.8,2.776,1.711,6.649a31.367,31.367,0,0,1-2.066,8.844h15.171s4.148.552,4.81,2.635-2.3,3.022-2.163,5.6,2.142,2.016,1.707,3.864-2.59,1.565-3.131,3.364,1.624,2.4,1.1,4.03-2.369.823-3.031,2.6,1.807,2.7.709,4.414-5.584,2.615-5.584,2.615-14.277-.2-20.739-1.233-4.122-1.381-4.122-1.381" transform="translate(-583.178 -602.197)" fill="#ecf0f3"/>
                    </svg>
                    <svg id="svg-dislike" data-name="svg-dislike" xmlns="http://www.w3.org/2000/svg" width="51.282" height="74" viewBox="0 0 51.282 48.802">
                        <path id="Path_100" data-name="Path 100" d="M691.872,639.935c2.865,2.531,12.464,1.15,12.464,1.15a5.643,5.643,0,0,0,2.436-.843,6.255,6.255,0,0,0,1.687-2.061V617.942a8.035,8.035,0,0,0-.99-2.309,5.079,5.079,0,0,0-1.633-1.439h-9.183a7.618,7.618,0,0,0-2.193.987,10.522,10.522,0,0,0-1.93,1.824S689.008,637.4,691.872,639.935Z" transform="translate(-657.177 -614.194)" fill="#ecf0f3"/>
                        <path id="Path_101" data-name="Path 101" d="M692.938,639.3l-1.9.281s-6.262,8.134-6.908,13.815.428,4.9,0,7.263-.694,1.872-1.711,2.2a5.207,5.207,0,0,1-2.356,0,6,6,0,0,1-3.163-2.2c-1.3-1.767-1.8-2.776-1.711-6.649a31.364,31.364,0,0,1,2.066-8.844H662.084s-4.148-.552-4.81-2.635,2.3-3.022,2.163-5.6-2.142-2.016-1.707-3.864,2.59-1.565,3.131-3.364-1.624-2.4-1.1-4.03,2.369-.823,3.031-2.6-1.807-2.7-.709-4.414,5.584-2.615,5.584-2.615,14.277.2,20.739,1.233,4.122,1.381,4.122,1.381" transform="translate(-657.177 -614.194)" fill="#ecf0f3"/>
                    </svg>
                </div>
            </div>
        </div>
        <ul class="video-list">
            <li>
                <span class="video"></span>
                <p class="description" v-fill="10"></p>
            </li>
            <li>
                <span class="video"></span>
                <p class="description" v-fill="10"></p>
            </li>
            <li>
                <span class="video"></span>
                <p class="description" v-fill="10"></p>
            </li>
            <li>
                <span class="video"></span>
                <p class="description" v-fill="10"></p>
            </li>
            <li>
                <span class="video"></span>
                <p class="description" v-fill="10"></p>
            </li>
            <li>
                <span class="video"></span>
                <p class="description" v-fill="10"></p>
            </li>
            <li>
                <span class="video"></span>
                <p class="description" v-fill="10"></p>
            </li>
            <li>
                <span class="video"></span>
                <p class="description" v-fill="10"></p>
            </li>
            <li>
                <span class="video"></span>
                <p class="description" v-fill="10"></p>
            </li>
            <li>
                <span class="video"></span>
                <p class="description" v-fill="10"></p>
            </li>
        </ul>
    </div>
</div>
</template>
<style lang="scss">
</style>

<script>
//import InstreamPreviewMixin from '../mixins/InstreamPreview';
import InstreamMixin from '../mixins/Instream';
import config from '../../config';
import { store } from '../../store';
import canAutoplay from '../../../lib/can-autoplay.min.js';

export default {
    name: 'DesktopInstream',
    mixins: [InstreamMixin],
    props: {
        adunit: {
            type: Object,
            default() {
                return {
                    mediaDataID: 0,
                    adformat: '',
                    size: '',
                    previewJson: false
                };
            }
        }
    },
    mounted() {
        canAutoplay
            .video({
                timeout: 500,
                muted: false,
                inline: true
            })
            .then((response) => {
                if (response.result === false) {
                    // Unmuted autoplay is not allowed.
                    this.render(true, true);
                } else {
                    // Unmuted autoplay is allowed.
                    this.render(false, true);
                }
            });
    },
    computed: {
        singleSizeMode() {
            return store.state.singleSizeMode;
        }
    }
}
</script>