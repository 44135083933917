
<template>
<div class="page">
    <header><h1></h1></header>
    <div class="row">
        <div class="col">
            <p img="4:3"></p>
            <p v-fill="21"></p>
            <p v-fill="50"></p>
            <p img="16:9"></p>
            <p v-fill="20"></p>
        </div>
        <div class="col">
            <p v-fill="50"></p>
            <p v-fill="40"></p>
            <p img="16:9"></p>
            <p v-fill="50"></p>
            <p v-fill="50"></p>
        </div>
        <div class="col ad-160x600" :class="[adunit.format]" ref="adunit">
        </div>
    </div>
</div>
</template>

<style lang="scss">
</style>

<script>
import TagsLoaderMixin from "../mixins/TagsLoader";

export default {
    name: 'Desktop160x600',
    mixins: [TagsLoaderMixin]
}
</script>