import config from "../config";
import BundleCacheService from "./BundleCacheService";

export default {
    /**
     * @param {string} hash
     * @param {Record<string, string>} params
     * @returns {Promise<VdxStudioDemopagesData>}
     */
    getPreviewBundle(hash, params) {
        return this.getBundleInfo({ hash }, params);
    },
    /**
     * @param {string} hash
     * @param {Record<string, string>} extraParams
     * @returns {Promise<VdxStudioDemopagesData>}
     */
    getPublishedBundle(hash, extraParams) {
        const params = Object.assign({ published: "true" }, extraParams);
        return this.getBundleInfo({ hash }, params);
    },
    /**
     * @param {string} bundleId
     * @param {Record<string, string>} extraParams
     * @returns {Promise<VdxStudioDemopagesData>}
     */
    getPublishedBundleByBundleId(bundleId, extraParams) {
        const params = Object.assign({ published: "true" }, extraParams);
        return this.getBundleInfo({ bundleId }, params);
    },

    /**
     * @param {{hash?: string; bundleId?: string}} param0
     * @param {Record<string, string>} params
     * @returns {Promise<VdxStudioDemopagesData>}
     */
    getBundleInfo({ hash, bundleId }, params) {
        let vdxStudioVersion;
        let demopagesAPIRequest;
        const route = hash ? "projects" : "bundles";
        const id = hash ? hash : bundleId;
        if (config.VDX_STUDIO_API_URL.indexOf("localhost") !== -1) {
            // Workaround for testing in local environment
            demopagesAPIRequest = new URL(config.VDX_STUDIO_API_URL + "/api/" + route + "/" + id + "/demopages");
        } else {
            demopagesAPIRequest = new URL("/vdxstudio/" + route + "/" + id + "/demopages", window.location.origin);
        }
        if (params) {
            demopagesAPIRequest.search = new URLSearchParams(params).toString();
        }

        const options = {
            method: "GET",
            headers: {
                "X-Server-Select": "latest",
            },
        };

        return fetch(demopagesAPIRequest, options)
            .then((response) => {
                vdxStudioVersion = response.headers.get("x-app-version");
                return response.json();
            })
            .then((res) => {
                if (!res.status) {
                    return false;
                }
                if (!res.data || !res.data.urls || !res.data.urls.length) {
                    return false;
                }
                res.data.urls = res.data.urls.map(function (v) {
                    v.url = window.VDX_STUDIO_API_URL + v.url;
                    return v;
                });
                if (!vdxStudioVersion) {
                    res.data.urls = res.data.urls.filter((size) => {
                        return !["CTV", "OTT"].includes(size.product);
                    });
                }
                res.data.vdxStudioVersion = vdxStudioVersion;
                return BundleCacheService.middleware(id, res.data);
            });
    },

    /**
     *
     * @param {string} hash
     * @param {string} revision
     * @returns {Promise<NeuronsApiMultiResponse | NeuronsApiSingleResponse>}
     */
    getNeuronsData(hash, revision = null) {
        let neuronsDataAPIRequest =
            "/proxy/" + encodeURIComponent(config.VDX_STUDIO_API_URL + "/api/projects/" + hash + "/neurons");

        if (revision != null) {
            neuronsDataAPIRequest += "?revision=" + revision;
        }

        const options = {
            method: "GET",
            headers: {
                "X-Server-Select": "latest",
            },
        };

        return fetch(neuronsDataAPIRequest, options)
            .then((response) => response.json())
            .then((res) => {
                return res;
            });
    },

    /**
     * @param {string} hash
     * @param {string} ccRevision
     * @param {string} ccRevisionVariation
     * @returns {Promise<CreativeConceptVariationInfo>}
     */
    ccvVariationInfo(hash, ccRevision, ccRevisionVariation) {
        const url = `${config.VDX_STUDIO_API_URL}/api/projects/${hash}/ccvVariationInfo/${ccRevision}/${ccRevisionVariation}`;
        const proxyUrl = "/proxy/" + encodeURIComponent(url);
        const options = {
            method: "GET",
            headers: {
                "X-Server-Select": "latest",
            },
        };

        return fetch(proxyUrl, options)
            .then((response) => response.json())
            .then((res) => {
                /**
                 * @type {CCVariationInfoApiResponse}
                 */
                const info = res.data[0];

                if (info) {
                    return {
                        vdxFrameworkTemplateVersion: info.frameworkTemplateVersion,
                        creativeConceptUrl: info.ccrvWidgetDeepLink,
                        showcaseLogoUrl: info.showcaseLogoUrl,
                        QRCodeExperience: info.QRCodeExperience,
                        isBokehModeEnabled: info.formData.isBokehMode,
                    };
                } else {
                    return {
                        vdxFrameworkTemplateVersion: "unknown",
                        creativeConceptUrl: "http://example.com/unknown-widget-url",
                        showcaseLogoUrl: "http://example.com/unknown-logo",
                        QRCodeExperience: "http://example.com/unknown-QRCodeExperience",
                        isBokehModeEnabled: null,
                    };
                }
            });
    },
};
