const openDB = async () => {
    return new Promise ((resolve, reject) => {
        const req = indexedDB.open('cache', 1, function(upgradeDb) {
            if (!upgradeDb.objectStoreNames.contains('bundles')) {
                upgradeDb.createObjectStore('bundles', {keyPath: 'hash'});
            }
        });
        req.onsuccess = (event) => {
            resolve(event.target.result);
        };
        req.onupgradeneeded = (event) => {
            const upgradeDb = event.target.result;
            if (!upgradeDb.objectStoreNames.contains('bundles')) {
                upgradeDb.createObjectStore('bundles', {keyPath: 'hash'});
            }
        };
        req.onerror = (error) => {
            reject(error)
        };
    });
};
const getValue = async (store, key) => {
    return new Promise ((resolve, reject) => {
        const req = store.get(key);
        req.onsuccess = (event) => {
            resolve(event.target.result);
        };
        req.onerror = (error) => {
            reject(error)
        };
    })
}
const putValue = async (store, value) => {
    return new Promise ((resolve, reject) => {
        const req = store.put(value);
        req.onsuccess = (event) => {
            resolve(event.target.result);
        };
        req.onerror = (error) => {
            reject(error)
        };
    })
}

export default {
    async middleware(key, value) {
        if (!('indexedDB' in window)) {
            if (value) {
                return value;
            } else {
                return null;
            }
        }
        
        const db = await openDB();
        const transaction = db.transaction('bundles', 'readwrite');
        const store = transaction.objectStore('bundles');
        const cachedBundle = await getValue(store, key);

        if (value) {
            const updatedBundle = { hash: key, ...value };
            if (cachedBundle) {
                updatedBundle.presentationMode = cachedBundle.presentationMode;
            }
            await putValue(store, updatedBundle);
            return updatedBundle;
        } else if (cachedBundle) {
            return cachedBundle;
        } else {
            return null;
        }
    },

    async setPresentationMode(hash, presentationMode) {
        const db = await openDB();
        const transaction = db.transaction('bundles', 'readwrite');
        const store = transaction.objectStore('bundles');
        const cachedBundle = await getValue(store, hash);
        await putValue(store, { ...cachedBundle, presentationMode });
    }
}