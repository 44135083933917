import config from './config';
import { wse, sessionId } from './wse';
import { store } from './store';

const SEND_EVENT = 'sendEvent';

const state = {
    hash: null,
    bundleId: null,
    sizes: [],
    isMobile: false,
    isInternalUser: false,
    byQRCode: false,
    openedAdSize: false
}

function getStatsData() {
    try {
        const { ad_size, format, platform } = store.state.bundle.urls[store.state.size];
        return { ad_size, format, platform };
    } catch (err) {
        return {};
    }
}

function idleLogout() {
    var timer;
    if (wse.is_online) {
        resetTimer();
    }
    window.onmousemove = resetTimer;
    window.onmousedown = resetTimer;
    window.ontouchstart = resetTimer;
    window.onclick = resetTimer;
    window.onkeypress = resetTimer;
    window.addEventListener('scroll', resetTimer, true);
    window.onmouseover = (e) => {
        if (e.target.nodeName === 'IFRAME') {
            if (e.target.src === 'about:blank' || e.target.id === '_expo_pubFiles_' ||
                e.target.id.includes('appsnack_teaser')) {
                postEvent('adunit-hover');
            }
        }
    }

    let reconnectAttempts = 0;
    wse.is_idle = false;
    wse.on('open', () => {
        if (reconnectAttempts) {
            reconnectAttempts = 0;
            return;
        }
        resetTimer();
    });
    wse.on('close', (code, reason) => {
        if (!wse.is_idle && reason !== 'wse-by-client') {
            reconnectAttempts++;
            if (reconnectAttempts > 10) {
                return;
            }
            console.log('Socket is closed. Reconnect will be attempted in 1 second.', code, reason);
            setTimeout(function () {
                console.log('Connect, reason - reconnect');
                wseConnect();
            }, 1000);
        }
    });
    window.addEventListener('message', (e) => {
        if (typeof (e.data) === 'string' && e.data[0] === '{') {
            const message = JSON.parse(e.data);
            if (message.type && (message.type === 'mousemove' || message.type === 'click')) {
                resetTimer();
            }

            if (message.eventName && (
                (message.eventName.startsWith('mainUnitLoad') && message.message) ||
                (message.eventName.startsWith('handleStateChange') && message.message.stateName === 'MAINUNIT')
            )
            ) {
                postEvent('adunit-expand');
            }
        }
        if (e.data && e.data.type === SEND_EVENT) {
            if (e.data.eventName === 'feature') {
                wseSend(e.data.eventName, { ...getStatsData(), ...e.data.eventData })
            } else {
                wseSend(e.data.eventName, getStatsData());
            }
        }
    })

    function resetTimer() {
        if (wse.is_online) {
            clearTimeout(timer);
            const timerStart = Date.now();
            timer = setTimeout(() => {
                wse.is_idle = true;
                wse.close(4000, 'app-session-timeout-' + timerStart + '-' + Date.now());
                console.log('Session timeout', config.FRONTEND_SESSION_TIMEOUT);
            }, config.FRONTEND_SESSION_TIMEOUT);
        } else {
            if (!wse.is_connecting && wse.is_idle) {
                wse.is_idle = false;
                console.log('Connect, reason - user has done action');
                state.openedAdSize = getStatsData();
                wseConnect();
            }
        }
    }
}

function wseConnect() {
    wse.connect({
        session_id: sessionId,
        hash: state.hash,
        bundle_id: state.bundleId,
        tz_offset: new Date().getTimezoneOffset(),
        sizes_list: state.sizes,
        is_mobile: state.isMobile,
        is_internal_user: state.isInternalUser,
        by_qrcode: state.byQRCode,
        user_agent: window.navigator.userAgent,
        session_timeout_ms: config.FRONTEND_SESSION_TIMEOUT,
        openedAdSize: state.openedAdSize || false
    });
}

function postEvent(eventName, eventData) {
    if (window === parent) {
        if (eventName === 'feature') {
            wseSend(eventName, { ...getStatsData(), ...eventData });
        } else {
            wseSend(eventName, getStatsData());
        }
    } else {
        window.parent.postMessage({ type: SEND_EVENT, eventName, eventData }, '*');
    }
}

function wseSend(eventName, data) {
    if (wse.is_online) {
        wse.send(eventName, data);
    } else {
        wse.once('open', function () {
            wse.send(eventName, data);
        });
    }
}

idleLogout();

export default {
    /**
     * Create new stats session
     * @typedef {[{ad_size: string, format: string, platform: string}]} SizesArray
     * @param {{hash: string, sizes: SizesArray, isMobile: boolean, isInternalUser: boolean, byQRCode: boolean}} session
     */
    createSession(session) {
        state.hash = session.hash;
        state.sizes = session.sizes;
        state.isMobile = session.isMobile;
        state.isInternalUser = session.isInternalUser;
        state.byQRCode = session.byQRCode;
        state.bundleId = session.bundleId;
        wseConnect();
    },
    /**
     * Send stats
     * @param eventName
     * @param eventData
     */
    send(eventName, eventData) {
        postEvent(eventName, eventData);
    },
    /**
     * Close current session
     */
    closeSession() {
        if (wse.is_online || wse.is_connecting) {
            wse.close();
        }
    }
}