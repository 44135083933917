<template>
<div class="page">
    <header><h1></h1></header>
    <div class="row">
        <div class="col ad-970x250" :class="[adunit.format]" ref="adunit">
            
        </div>
    </div>
    <div class="row">
        <div class="col">
            <h3 v-fill="5"></h3>
            <p v-fill="50"></p>
            <p img="1:2"></p>
            <p v-fill="50"></p>
        </div>
        <div class="col">
            <h4 v-fill="5"></h4>
            <p v-fill="55"></p>
            <p v-fill="21"></p>
            <p v-fill="12"></p>
            <p img="16:9"></p>
        </div>
        <div class="col">
            <p img="16:9"></p>
            <h5 v-fill="5"></h5>
            <p v-fill="21"></p>
            <p v-fill="12"></p>
            <p v-fill="55"></p>
        </div>
    </div>
</div>
</template>

<style lang="scss">
</style>

<script>
import TagsLoaderMixin from "../mixins/TagsLoader";

export default {
    name: "Desktop970x250",
    mixins: [TagsLoaderMixin]
}
</script>
