import config from "../../config";
import postscribe from "../../../lib/postscribe";
import { store } from "../../store";
import { E9Tags } from "../../entities/e9Tags";

export default {
    props: {
        adunit: {
            type: Object,
            default() {
                return {
                    ccRevision: "",
                    ccRevisionVariation: "",
                    vdxFrameworkTemplateVersion: "",
                    mediaDir: "",
                    mediaDataID: 0,
                    mediaID: 0,
                    format: "",
                    adformat: "",
                    size: "",
                    platform: "",
                    previewJson: false,
                    type: "expotask",
                };
            },
        },
    },
    mounted() {
        this.render();
        store.state.adunitElement = this.$refs["adunit"];
    },
    methods: {
        render() {
            if (this.adunit.previewJson) {
                // Render VDX Studio preview
                this.renderPreview();
            } else {
                // Render published adunit
                this.renderPublished();
            }
        },
        renderPublished() {
            this.$refs.adunit.innerHTML = "";
            const e9 = new E9Tags(this.adunit);
            if (store.state.presentationMode) {
                e9.enablePresentationMode();
            }
            if (
                !store.state.isMobile &&
                (this.adunit.adformat === "VdxConnectMobileExpandable" ||
                    this.adunit.adformat === "VdxMobileExpandable")
            ) {
                e9.disableFrameBusting();
            }
            // Temporary solution for test purpose
            if (store.state.isMobile) {
                e9.enableFrameBusting();
            }
            const width = this.adunit.size.split("x")[0];
            const height = this.adunit.size.split("x")[1];

            const html =
                "<script>var e9 = new Object();" +
                e9.toJSString() +
                "</script>" +
                "<SCRIPT type='text/javascript' SRC='" +
                (this.adunit.config.tagsJsUrl || config.TAGS_JS_URL) +
                "'></SCRIPT>";
            if (window === top) {
                this.injectIFrame(width, height, html);
            } else {
                this.injectPostscribe(html);
            }
        },
        renderPreview() {
            this.$refs.adunit.innerHTML = "";
            if (!this.adunit.previewJson.baseUrl) {
                Object.assign(this.adunit.previewJson, { baseUrl: config.VDX_STUDIO_API_URL });
            }
            Object.assign(this.adunit.previewJson, { containerId: "adunit" });
            let mockId = this.adunit.mediaDataID;
            var templateJson = {
                ...this.adunit.previewJson,
            };
            if (templateJson.teaser) {
                templateJson.teaser.baseUrl = this.adunit.previewJson.baseUrl;
            }
            templateJson[this.adunit.previewJson.adSize] = {
                states: this.adunit.previewJson.states,
                locale: this.adunit.previewJson.locale,
                containerId: this.adunit.previewJson.containerId,
                peelConfig: this.adunit.previewJson.peelConfig,
            };
            const e9 = new E9Tags(this.adunit);
            e9.setCustomTags({
                mediaDataID: mockId,
                isByPassCrossDomain: this.adunit.previewJson.adSize === "320x50",
                fragmentedVideo: { enabled: false },
                elastic: this.adunit.previewJson.elastic,
                creativeBuilderCfg: templateJson,
                end: "display",
            });
            if (store.state.presentationMode) {
                e9.enablePresentationMode();
            }
            if (
                !store.state.isMobile &&
                (this.adunit.adformat === "VdxConnectMobileExpandable" ||
                    this.adunit.adformat === "VdxMobileExpandable")
            ) {
                e9.disableFrameBusting();
            }
            // Temporary solution for test purpose
            if (store.state.isMobile) {
                e9.enableFrameBusting();
            }

            const html =
                "<script>var e9 = new Object();" +
                e9.toJSString() +
                "</script>" +
                "<SCRIPT type='text/javascript' SRC='" +
                config.TAGS_JS_URL +
                "'></SCRIPT>";
            const width = this.adunit.previewJson.adSize.split("x")[0];
            const height = this.adunit.previewJson.adSize.split("x")[1];

            if (window === top) {
                this.injectIFrame(width, height, html);
            } else {
                this.injectPostscribe(html);
            }
        },
        injectPostscribe(html) {
            postscribe(this.$refs.adunit, html);
        },
        injectIFrame(width, height, html) {
            const frameHtml = '<!DOCTYPE html><html><head></head><body id="adunit">' + html + "</body></html>";
            var iframeID = "expo_" + new Date().getTime();
            const frame = document.createElement("iframe");
            frame.id = iframeID;
            frame.setAttribute("width", width);
            frame.setAttribute("height", height);
            frame.src = "about:blank";
            frame.setAttribute("marginwidth", "0");
            frame.setAttribute("align", "center");
            frame.setAttribute("marginheight", "0");
            frame.setAttribute("hspace", "0");
            frame.setAttribute("vspace", "0");
            frame.setAttribute("frameBorder", "0");
            frame.setAttribute("scrolling", "no");
            frame.setAttribute("allowTransparency", "true");
            if (this.adunit.format === "inframe") {
                frame.style.outline = "black solid 1px";
            }
            this.$refs.adunit.appendChild(frame);
            frame.contentDocument.open();
            frame.contentDocument.write(frameHtml);
            frame.contentDocument.close();
            const adContainerSize = frame.getClientRects()[0];
            if (adContainerSize.width < width || adContainerSize.height < height) {
                store.setWarning(config.WARNINGS.ZOOMED_UI);
            }
        },
    },
};
