import EventEmitter3 from './eventemitter3.min.js';

class WseClient extends EventEmitter3 {
    constructor(url, options, wse_protocol = null) {
        super();
        this.is_online = false;
        this.is_connecting = false;
        this.protocol = wse_protocol || new WseDefaultProtocol();
        this.url = url;
        this.options = options;
        this.emit_message = true;
        this.emit_message_prefix = 'm:';
        this.reused = 0;
    }

    connect(payload) {
        this.reused++;
        this.is_online = null;
        this.is_connecting = true;
        this.ws = new WebSocket(this.url, this.protocol.name, this.options);
        this.ws.onopen = () =>  {
            this.is_connecting = false;
            this.is_online = true;
            this.send(this.protocol.hi, payload);
        }
        this.ws.onmessage = (m) => this._before_welcome(m);
        this.ws.onerror = (e) => this.emit('error', e);
        this.ws.onclose = (event) => {
            this.is_online = false;
            this.is_connecting = false;
            this.emit('close', event.code, event.reason);
        };
        return this;
    }

    _before_welcome(message) {
        let m = this.protocol.unpack(message.data);
        this.is_online = true;
        if (m.c === this.protocol.hi) {
            this.emit('open', m.dat); //for capability
            this.emit(this.protocol.hi, m.dat);
        }
        this.ws.onmessage = (msg) => this._data(msg);
    }

    _data(message) {
        let m = this.protocol.unpack(message.data);
        if (this.emit_message)
            this.emit(this.emit_message_prefix + m.c, m.dat);
        this.emit('message', m.c, m.dat);
    };

    send(c, dat) {
        if (this.ws && this.ws.readyState === WebSocket.OPEN) {
            this.ws.send(this.protocol.pack(c, dat));
        } else {
            this.emit('error', new Error('socket-not-opened'))
        }
    }

    close(code = 1000, reason = WSE_REASON.BY_CLIENT) {
        if (this.ws)
            this.ws.close(code, reason);
    }
}

class WseDefaultProtocol {
    constructor() {
        this.name = 'wse-default-json';
        this.hi = 'hello';
    }

    pack(c, dat) {
        return JSON.stringify({
            c: c,
            dat: dat
        });
    }

    unpack(string) {
        return JSON.parse(string);
    }
}

const WSE_REASON = {
    OTHER_CLIENT_CONECTED: 'wse-other-client-connection',
    NOT_AUTHORIZED: 'wse-not-authorized',
    SIGNOUT: 'wse-signout',
    PROTOCOL_ERR: 'wse-invalid-protocol',
    NO_REASON: 'wse-unknown-reason',
    BY_CLIENT: 'wse-by-client',
};

export default WseClient;
