import config from "./config";
import ExpotaskService from "./services/ExpotaskService";
import VDXStudioService from "./services/VDXStudioService";
import BundleCacheService from "./services/BundleCacheService";

const WHITELIST = config.ADSIZES_WHITELIST;
const NAMES = {
    TV: {
        "1x10": "CTV",
    },
    OTT: {
        "1x10": "OTT",
    },
    desktop: {
        "1x10": "InRead",
        preroll: {
            "1x10": "Desktop Preroll",
        },
    },
    mobile: {
        "1x10": "InRead",
        preroll: {
            "1x10": "Mobile Preroll",
        },
    },
    instream: {
        "1x10": "Desktop Instream",
    },
};
const SOLUTION = {
    VDX_TV_VIDEO_MAGNIFY_COMBINED: "VDX TV + Video Magnify combined",
    VDX_TV_MAGNIFY: "VDX TV Magnify",
    VDX_VIDEO_MAGNIFY: "VDX Video Magnify",
};
let deviceWidth = screen.width;
let IS_MOBILE = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

/**
 * @type {Store}
 */
const store = {
    CURR_ID: null,
    state: {
        mediaDataID: "00000000",
        isCreativeConceptFlow: false,
        creativeConceptFlowType: "unknown",
        vdxFrameworkTemplateVersion: "",
        creativeConceptUrl: "",
        bundleDeepLink: "",
        ccRevisionVariation: "",
        ccRevision: "",
        isCCInfoInUrl: false,
        urlCCRevision: "",
        urlCCRevisionVariation: "",
        hash: null,
        revision: null,
        bundle: {},
        sizes: [],
        size: null,
        defaultSizeId: null,
        useCacheOnly: false,
        presentationMode: JSON.parse(sessionStorage.getItem("presentationMode")) || false,
        isBokehMode: sessionStorage.getItem("isBokehMode") ? JSON.parse(sessionStorage.getItem("isBokehMode")) : null,
        hasMobileAdunits: false,
        vpnConnection: false,
        warnings: [],
        mockId: null,
        adunit: {
            adFormatId: null,
            platform: null,
        },
        singleSizeMode: false,
        isMobile: deviceWidth < 930, ///Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        publisherPlayerSound: localStorage.getItem("publisherPlayerSound"),
        groups: false,
        isVDXStudioPreviewFlow: false,
    },
    setCreativeConceptFrameworkVersion(version) {
        this.state.vdxFrameworkTemplateVersion = version;
    },
    setCreativeConceptMediaDataId(id) {
        this.state.mediaDataID = id;
    },
    setCreativeConceptFlow(value, type) {
        this.state.isCreativeConceptFlow = value;
        this.state.creativeConceptFlowType = type;
    },
    setCreativeConcept(ccRevision, ccRevisionVariation) {
        this.state.ccRevision = ccRevision;
        this.state.ccRevisionVariation = ccRevisionVariation;
    },
    setCreativeConceptInfoFromURL(ccRevision, ccRevisionVariation) {
        this.state.isCCInfoInUrl = true;
        this.state.urlCCRevision = ccRevision;
        this.state.urlCCRevisionVariation = ccRevisionVariation;
    },
    setHash(hash) {
        this.state.hash = hash;
    },
    setMockId(mockId) {
        this.state.mockId = mockId;
    },
    setSize(sizeId) {
        this.state.size = sizeId;
    },
    setWarning(warning, placeholders, tag, handlers) {
        let level = warning.level || "error";
        let message = warning.message || warning;
        if (placeholders) {
            for (let placeholder in placeholders) {
                message = message.replace("${" + placeholder + "}", placeholders[placeholder]);
            }
        }
        if (this.state.warnings.find((w) => w.message === message) === undefined) {
            this.state.warnings.push({
                level,
                message,
                tag,
                handlers: handlers,
            });
        }
    },
    setSingleSizeMode() {
        this.state.singleSizeMode = true;
    },
    setPublisherPlayerSound(volume) {
        localStorage.setItem("publisherPlayerSound", volume);
        this.state.publisherPlayerSound = volume;
        window.postMessage("showcase://" + JSON.stringify({ type: "changePublisherPlayerVolume", value: volume }));
    },
    setVDXStudioPreviewFlow(val) {
        this.state.isVDXStudioPreviewFlow = val;
    },
    setRevision(revision) {
        this.state.revision = revision;
    },
    removeWarning(warning) {
        const i = this.state.warnings.indexOf(warning);
        if (i !== -1) {
            this.state.warnings.splice(i, 1);
        }
    },
    removeWarningsByTag(tag) {
        this.state.warnings = this.state.warnings.filter((warning) => warning.tag && warning.tag !== tag);
    },
    async togglePresentationMode() {
        this.state.presentationMode = !this.state.presentationMode;
        sessionStorage.setItem("presentationMode", this.state.presentationMode);
        if (this.state.hash) {
            await BundleCacheService.setPresentationMode(this.state.hash, this.state.presentationMode);
        }
    },
    toggleBokehMode() {
        this.state.isBokehMode = !this.state.isBokehMode;
        sessionStorage.setItem("isBokehMode", this.state.isBokehMode);
    },
    async fetchPublishedBundle(hash) {
        if (hash.length === 6 || hash.length === 7 || hash.length === 13) {
            // 6-symbol hash - old non single execution bundle
            // 7-symbol hash - Saleskit bundle
            let bundle = await ExpotaskService.getBundleInfo(hash);
            let bundle2;
            const isCCRV = bundle.ccRevision != null;

            if (bundle.is_se) {
                if (isCCRV === true) {
                    this.setCreativeConceptFlow(true, "publish");
                    this.setCreativeConcept(bundle.ccRevision, String(bundle.ccRevisionVariation));
                }

                const params = isCCRV
                    ? {
                          ccRevision: bundle.ccRevision,
                          ccRevisionVariation: String(bundle.ccRevisionVariation),
                      }
                    : {};
                const bundleFromVDXStudio = await VDXStudioService.getPublishedBundleByBundleId(
                    String(bundle.bundle_id),
                    params
                );
                if (bundleFromVDXStudio) {
                    bundle2 = {
                        ...bundleFromVDXStudio,
                        brand_name: bundle.brand_name,
                        name: bundle.name,
                        is_se: true,
                        bundle_id: bundle.bundle_id,
                        presentationMode: bundle.presentationMode,
                        config: bundle.config,
                        urls: bundleFromVDXStudio.urls.concat(
                            bundle.urls.filter((size) => !size["_creative_request_id"])
                        ),
                    };
                } else if (!bundle?.urls.length) {
                    throw new Error("Invalid bundle");
                }
            }

            return bundle2 ?? bundle;
        } else {
            /**
             * @type {Bundle}
             */
            let bundle;
            // VDX Studio bundle
            const extraParams = this.state.revision
                ? {
                      revision: this.state.revision.toString(),
                  }
                : {};
            const creativeConceptParams = {
                ccRevision: this.state.urlCCRevision,
                ccRevisionVariation: this.state.urlCCRevisionVariation,
            };
            const params = this.state.isCCInfoInUrl ? creativeConceptParams : extraParams;

            const bundleFromVDXStudio = await VDXStudioService.getPublishedBundle(hash, params);

            if (bundleFromVDXStudio.isCCRV === true) {
                this.setCreativeConceptFlow(true, "publish");

                if (this.state.isCCInfoInUrl) {
                    this.setCreativeConcept(this.state.urlCCRevision, this.state.urlCCRevisionVariation);
                } else {
                    this.setCreativeConcept(
                        bundleFromVDXStudio.latestRevision,
                        bundleFromVDXStudio.latestVariation.toString()
                    );
                }
            }

            const isCreativeConceptFlow = this.state.isCreativeConceptFlow;
            const ccRevision = isCreativeConceptFlow ? this.state.ccRevision : null;
            const ccRevisionVariation = isCreativeConceptFlow ? this.state.ccRevisionVariation : null;

            // Check if there are custom adunits in Expotask
            const bundleFromExpotask = await ExpotaskService.getBundleInfo(hash + "1", ccRevision, ccRevisionVariation);
            if (!bundleFromVDXStudio) {
                // Check if it's only custom product in bundle
                if (bundleFromExpotask?.urls.length) {
                    return bundleFromExpotask;
                } else {
                    throw new Error("Invalid bundle");
                }
            }
            if (!bundleFromVDXStudio.logo_url || bundleFromVDXStudio.flow === "non-muse") {
                // Non muse package bundles doesn't have showcase logo in VDX Studio
                // Need to fetch it from Expotask
                bundle = { ...bundleFromExpotask };
                bundle.logo_url = bundleFromVDXStudio.logo_url;
                bundle.urls = bundleFromVDXStudio.urls;
                bundle.presentationMode = bundleFromVDXStudio.presentationMode;
                bundle.isBokehModeEnabled = bundleFromVDXStudio.isBokehModeEnabled;
                bundle.vdxStudioVersion = bundleFromVDXStudio.vdxStudioVersion;
                bundle.flow = bundleFromVDXStudio.flow;
                bundle.isCCRV = bundleFromVDXStudio.isCCRV;
                bundle.bundle_url = bundleFromVDXStudio.bundle_url;
                bundle.latestRevision = bundleFromVDXStudio.latestRevision;
                bundle.latestVariation = bundleFromVDXStudio.latestVariation;
                bundle.hash = bundleFromVDXStudio.hash;
            } else {
                bundle = bundleFromVDXStudio;
            }

            bundle.is_se = true;
            if (!bundle || !bundle.urls.length) {
                throw new Error("adunit is not ready");
            }
            if (bundleFromExpotask) {
                // Get custom products and AdEx json only from Expotask
                bundle.urls = bundleFromVDXStudio.urls.concat(
                    bundleFromExpotask.urls.filter((size) => !size["_creative_request_id"])
                );
                bundle.config = bundleFromExpotask.config;
            }

            return bundle;
        }
    },
    async fetchBundleInfo() {
        let bundle;
        if (this.state.isVDXStudioPreviewFlow) {
            const creativeParams = {
                ccRevision: this.state.ccRevision,
                ccRevisionVariation: this.state.ccRevisionVariation,
            };
            const params = this.state.isCreativeConceptFlow ? creativeParams : {};
            // Preview flow
            bundle = await VDXStudioService.getPreviewBundle(this.state.hash, params);
        } else {
            // Publish flow
            bundle = await this.fetchPublishedBundle(this.state.hash);

            this.state.bundleDeepLink = bundle.bundle_url;
        }

        const filteredBundles = await this.filterBundleUrls(bundle);

        if (this.state.isCreativeConceptFlow) {
            if (this.state.creativeConceptFlowType === "publish") {
                // Publish
                const hash = bundle.hash;
                const ccRevisionVariation = this.state.ccRevisionVariation;
                const ccRevision = this.state.ccRevision;
                const ccvInfo = await VDXStudioService.ccvVariationInfo(hash, ccRevision, ccRevisionVariation);
                this.state.vdxFrameworkTemplateVersion = ccvInfo.vdxFrameworkTemplateVersion;
                this.state.creativeConceptUrl = ccvInfo.creativeConceptUrl;
                filteredBundles.logo_url = ccvInfo.showcaseLogoUrl;
                filteredBundles.isBokehModeEnabled = ccvInfo.isBokehModeEnabled ?? filteredBundles.isBokehModeEnabled;
                this.state.isBokehMode = filteredBundles.isBokehModeEnabled;

                const neuronsHash = [hash, ccRevision, ccRevisionVariation].join("/");
                const neuronsData = await VDXStudioService.getNeuronsData(neuronsHash);
                const ctvBundles = filteredBundles.urls.filter((x) => x.product === "CTV");

                ctvBundles.forEach((x) => (x.QRCodeExperience = ccvInfo.QRCodeExperience));

                if ("CTV" in neuronsData) {
                    if (neuronsData.CTV.status === "ready") {
                        ctvBundles.forEach((x) => {
                            x.heatmapVideoUrl = neuronsData.CTV.heatmapVideoUrl;
                            x.neuronsReady = true;
                        });
                    } else {
                        ctvBundles.forEach((x) => (x.neuronsReady = false));
                    }
                }

                if ("OTT" in neuronsData) {
                    const ottBundles = filteredBundles.urls.filter((x) => x.product === "OTT");

                    if (neuronsData.OTT.status === "ready") {
                        ottBundles.forEach((x) => {
                            x.heatmapVideoUrl = neuronsData.OTT.heatmapVideoUrl;
                            x.neuronsReady = true;
                        });
                    } else {
                        ottBundles.forEach((x) => (x.neuronsReady = false));
                    }
                }
            } else {
                // Preview
                const mediaDataID = this.state.mediaDataID;
                filteredBundles.urls.forEach((x) => (x.mockid = mediaDataID));
                this.state.creativeConceptUrl = "https://example.com/no-url-for-preview";
            }
        }

        this.state.bundle = filteredBundles;
    },
    supportForCombinedMagnify(sizes) {
        const addedSizes = [];
        const updatedSizes = sizes.map((size) => {
            if (!["TV", "OTT"].includes(size.platform)) {
                // Copy sizes to TV Magnify tab
                if (size.format !== "instream" && size.platform !== "instream" && size.ad_size !== "320x50") {
                    addedSizes.push({ ...size, solution: SOLUTION.VDX_TV_MAGNIFY });
                }
                return { ...size, solution: SOLUTION.VDX_VIDEO_MAGNIFY };
            } else if (size.platform === "OTT") {
                return { ...size, solution: SOLUTION.VDX_VIDEO_MAGNIFY };
            } else if (size.platform === "TV") {
                return { ...size, solution: SOLUTION.VDX_TV_MAGNIFY };
            }
            return { ...size };
        });
        if (addedSizes.length) {
            return updatedSizes.concat(addedSizes);
        }

        return sizes;
    },
    async filterBundleUrls(bundle) {
        this.state.groups = false;
        let groups = new Set();

        if (bundle.urls.find((size) => size.solution === SOLUTION.VDX_TV_VIDEO_MAGNIFY_COMBINED)) {
            bundle.urls = this.supportForCombinedMagnify(bundle.urls);
            this.state.groups = true;
            groups = new Set();
        }

        let raw = bundle.urls;
        const bundleSolution = raw.find((adunit) => adunit.solution)?.solution;
        if (bundle.brand_name && bundle.brand_name.toLowerCase().indexOf("test") === -1) {
            document.title = bundle.brand_name;
        } else {
            document.title = "Client's Showcase";
        }
        this.state.hasMobileAdunits = false;
        this.state.presentationMode = !!bundle.presentationMode;
        this.state.isBokehMode = this.state.isBokehMode !== null ? this.state.isBokehMode : !!bundle.isBokehModeEnabled;
        if (bundle.presentationMode === undefined && this.isInternalUser() && !this.state.isVDXStudioPreviewFlow) {
            this.togglePresentationMode();
        }
        sessionStorage.setItem("presentationMode", String(this.state.presentationMode));

        const linkToBundle = raw.find((size) => size.videoMagnifyShowcaseUrl);
        if (linkToBundle && !this.state.isMobile) {
            const hash = linkToBundle.videoMagnifyShowcaseUrl.split("#")[1].split("/")[0];
            const linkedBundle = await this.fetchPublishedBundle(hash);
            linkedBundle.urls = linkedBundle.urls.map((size) => {
                if (!size.solution) {
                    size.solution = "VDX Video Magnify";
                }
                return size;
            });
            raw = raw.concat(linkedBundle.urls);
            bundle.urls = raw;
            groups = new Set();
        }

        let i = raw.length;

        // AdEx ad size has type property
        const adexAdSizes = raw.reduce(
            (a, size) => (size.type && (a[size.platform + size.format + size.ad_size] = true), a),
            {}
        );

        // Check if mobile inread adunit exists
        // if doesn't exist - add one based on instream adunit
        const mobileInreadExists = raw.some((v) => v.platform === "mobile" && v.format === "inread");

        let filter;

        while (i--) {
            if (
                typeof WHITELIST[raw[i].platform] === "undefined" ||
                typeof WHITELIST[raw[i].platform][raw[i].ad_size] === "undefined"
            ) {
                if (raw[i].type === "filter") {
                    filter = raw[i];
                }
                console.info("UNACCEPTABLE SIZE/PLATFORM:", raw[i].platform + " / " + raw[i].ad_size, raw[i]);
                raw.splice(i, 1);
                continue;
            }

            if (!raw[i].solution && bundleSolution) {
                raw[i].solution = bundleSolution;
            }

            // Remove Expotask ad size if AdEx ad size is presents
            if (!raw[i].type && adexAdSizes[raw[i].platform + raw[i].format + raw[i].ad_size]) {
                raw.splice(i, 1);
                continue;
            }

            raw[i].config = {
                ...bundle.config,
                ...raw[i].config,
            };

            if (groups) {
                if (!raw[i].solution) {
                    raw[i].solution = "VDX TV Magnify";
                }
                groups.add(raw[i].solution);
            }
            if (raw[i].format === "instream" && !bundle.vdxStudioVersion) {
                console.log("INREAD GENERATOR: ", raw[i]);
                if (raw[i].platform === "mobile") {
                    if (!mobileInreadExists) {
                        // Add mobile inread if mobile instream exists
                        const inread = { ...raw[i] };
                        inread.name = "InRead";
                        inread.platform = "mobile";
                        inread.format = "inread";
                        inread.bezel = true;
                        inread.order = 1;
                        inread._raw_i = raw.length;
                        inread.url =
                            window.location.origin +
                            "/demopage/" +
                            store.state.hash +
                            "/" +
                            raw.length +
                            window.location.search;
                        raw.push(inread);
                    }
                } else if (
                    raw[i].platform === "instream" &&
                    !raw.find(
                        (a) => a.platform === "desktop" && a.format === "inread" && a.solution === raw[i].solution
                    )
                ) {
                    // Add desktop inread if desktop instream exists
                    const inread = { ...raw[i] };
                    inread.platform = "desktop";
                    inread.format = "inread";
                    inread.name = NAMES.desktop["1x10"];
                    inread.order = 1;
                    inread._raw_i = raw.length;
                    raw.push(inread);
                }
            }

            if (raw[i].platform === "TV" && raw[i].solution === "VDX Video Magnify") {
                raw[i].platform = "OTT";
            }

            if (raw[i].platform === "TV" && raw[i].QRCodeExperience) {
                const QRCodeExperience = {
                    ...raw[i],
                    name: "QR Code Experience",
                    ad_size: "qrcodeexperience",
                    defaultSizeOrder: 3,
                    _raw_i: raw.length,
                };
                if (raw[i].neurovision) {
                    delete QRCodeExperience.neurovision;
                }
                raw.push(QRCodeExperience);
            }

            if (raw[i].platform === "mobile" && raw[i].ad_size === "320x50") {
                raw[i].format = "adhesion";
            }

            if (raw[i].platform === "mobile" || raw[i].format === "mobile") {
                this.state.hasMobileAdunits = true;
                if (!raw[i].config) {
                    raw[i].config = {};
                }
                raw[i].config.isBokehMode = this.state.isBokehMode;

                if (!this.state.isMobile && !this.state.isVDXStudioPreviewFlow) {
                    raw[i].url =
                        window.location.origin +
                        "/mock/" +
                        raw[i].mockid +
                        "/" +
                        raw[i].ad_size +
                        "/mobile" +
                        (raw[i].format === "inread" ? "/inread" : "");
                } else if (!this.state.isMobile && this.state.isVDXStudioPreviewFlow) {
                    raw[i].url =
                        window.location.origin + "/demopage/" + store.state.hash + "/" + i + window.location.search;
                }
            }
            if ((raw[i].platform === "TV" || raw[i].platform === "OTT") && raw[i].neurovision) {
                raw[i].showHeatmap = false;
                raw[i].neuronsReady = raw[i].neuronsReady || false;
            }
        }

        if (filter) {
            bundle.urls = raw = this.filterSizes(raw, filter);
        }

        // The first tab has to be VDX TV Magnify
        this.state.groups = groups ? new Set(Array.from(groups).sort()) : false;

        let currentSizeOrder = Number.MAX_SAFE_INTEGER;
        for (let i = 0; i < raw.length; i++) {
            if (!(this.state.isMobile && raw[i].platform !== "mobile")) {
                let defaultSizeOrder =
                    raw[i].defaultSizeOrder ||
                    config.DEFAULT_SIZE_ORDER[raw[i].platform + "." + raw[i].format + "." + raw[i].ad_size];
                defaultSizeOrder = defaultSizeOrder || config.DEFAULT_SIZE_ORDER[raw[i].platform + "." + raw[i].format];
                defaultSizeOrder = defaultSizeOrder || config.DEFAULT_SIZE_ORDER[raw[i].platform];
                defaultSizeOrder = defaultSizeOrder || Number.MAX_SAFE_INTEGER;
                if (defaultSizeOrder <= currentSizeOrder) {
                    currentSizeOrder = defaultSizeOrder;
                    this.state.defaultSizeId = i;
                }
            }
            raw[i]._raw_i = i;
            let alter_name = NAMES[raw[i].platform][raw[i].ad_size];
            if (raw[i].format === "preroll") {
                alter_name = NAMES[raw[i].platform][raw[i].format][raw[i].ad_size];
            }
            raw[i].name = raw[i].name ? raw[i].name : alter_name ? alter_name : raw[i].ad_size;
            raw[i].order = raw[i].order || WHITELIST[raw[i].platform][raw[i].ad_size];
            raw[i].config = {
                ...bundle.config,
                ...raw[i].config,
            };
        }

        return bundle;
    },
    filterSizes(sizes, filter) {
        const rules = filter.show || filter.hide;
        const filterFunc = (size) => {
            for (const rule of rules) {
                let followRule = true;
                if (rule.size) {
                    rule.ad_size = rule.size;
                    delete rule.size;
                }
                for (const field in rule) {
                    if (Array.isArray(rule[field])) {
                        if (!rule[field].includes(size[field])) {
                            followRule = false;
                            break;
                        }
                    } else if (typeof rule[field] === "string") {
                        if (size[field] !== rule[field]) {
                            followRule = false;
                            break;
                        }
                    }
                }
                if (followRule) {
                    return true;
                }
            }
            return false;
        };
        if (filter.show) {
            return sizes.filter(filterFunc);
        } else if (filter.hide) {
            return sizes.filter((v) => !filterFunc(v));
        } else {
            return sizes;
        }
    },
    async fetchBundleByJSONUrl(url) {
        this.state.groups = false;
        const jsonResponse = await fetch("/proxy/" + encodeURIComponent(url));
        if (!jsonResponse.ok) {
            return false;
        }
        const bundleJson = await jsonResponse.json();

        let currentSizeOrder = Number.MAX_SAFE_INTEGER;
        this.state.defaultSizeId = 0;
        bundleJson.urls.forEach((url, i) => {
            url._raw_i = i;
            url.name = url.name || url.ad_size;

            if (url.platform === "mobile" && url.ad_size === "320x50") {
                url.format = "adhesion";
            }

            if (url.platform === "mobile" || url.format === "mobile") {
                this.state.hasMobileAdunits = true;
                url.url =
                    window.location.origin +
                    "/" +
                    url.type +
                    "/" +
                    url.mediaDataID +
                    "/" +
                    url.ad_size +
                    "/mobile" +
                    (url.format === "inread" ? "/inread" : "");
            }
            url.config = {
                ...bundleJson.config,
                ...url.config,
            };

            if (!(this.state.isMobile && url.platform !== "mobile")) {
                let defaultSizeOrder = config.DEFAULT_SIZE_ORDER[url.platform + "." + url.format + "." + url.ad_size];
                defaultSizeOrder = defaultSizeOrder || config.DEFAULT_SIZE_ORDER[url.platform + "." + url.format];
                defaultSizeOrder = defaultSizeOrder || config.DEFAULT_SIZE_ORDER[url.platform];
                defaultSizeOrder = defaultSizeOrder || Number.MAX_SAFE_INTEGER;
                if (defaultSizeOrder <= currentSizeOrder) {
                    currentSizeOrder = defaultSizeOrder;
                    this.state.defaultSizeId = i;
                }
            }
        });
        this.state.bundle = bundleJson;
        return bundleJson;
    },
    async fetchMockInfo() {
        const adFormatId = await ExpotaskService.getMockInfo(this.state.mockId);
        let platform = "desktop";
        if ([78, 80, 82, 90, 91, 92, 93, 94, 98].includes(adFormatId)) {
            platform = "mobile";
        }
        this.state.adunit.adFormatId = adFormatId;
        this.state.adunit.platform = platform;
    },

    async getNeuronsData(hash) {
        return VDXStudioService.getNeuronsData(hash);
    },
    creativeIsFromVDXStudio() {
        return (
            this.state.hash &&
            (this.state.hash[0] === "$" ||
                (this.state.hash.length !== 13 && this.state.hash.length !== 7 && this.state.hash.length !== 6))
        );
    },
    creativeIsFromSaleskit() {
        return this.state.hash && this.state.hash.length === 7 && this.state.bundle.bundle_id;
    },
    getSaleskitUrl() {
        if (this.creativeIsFromSaleskit()) {
            return config.SALESKIT_URL + "/" + this.state.bundle.bundle_id;
        } else {
            return false;
        }
    },
    isInternalUser() {
        if (this.state.vpnConnection || document.cookie.split(";").find((c) => c.includes("isInternalUser=true"))) {
            return true;
        }
        return false;
    },
    async isVPNConnection() {
        return (this.state.vpnConnection = await new Promise(function (resolve) {
            setTimeout(function () {
                resolve(false);
            }, 1000);
            fetch(config.VPN_TEST_URL)
                .then(() => resolve(true))
                .catch(() => resolve(false));
        }));
    },
};

window.addEventListener("resize", (e) => {
    if (deviceWidth !== screen.width) {
        deviceWidth = screen.width;
        store.state.isMobile = deviceWidth < 930;
    }
});

window.addEventListener("storage", (e) => {
    console.log("STORAGE");
    if (e.key === "publisherPlayerSound") {
        store.state.publisherPlayerSound = e.newValue;
    }
});

export { WHITELIST, NAMES, IS_MOBILE, store };
