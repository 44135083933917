import config from '../../config';
import { E9Tags } from '../../entities/e9Tags';
import { store } from '../../store';

export default {
    data() {
        return {
            vjs: null,
            isMuted: false,
            state: store.state
        }
    },
    computed: {
        singleSizeMode() {
            return store.state.singleSizeMode;
        }
    },
    mounted() {
        this.postMessageListener = this.postMessageListener.bind(this);
        window.addEventListener('message', this.postMessageListener);
    },
    beforeDestroy() {
        window.init = false;
        window.removeEventListener('message', this.postMessageListener);
    },
    methods: {
        render(isMuted, showControls) {
            if (this.adunit.previewJson) {
                // Render VDX Studio preview
                this.renderPreview(isMuted, showControls);
            } else {
                // Render published adunit
                this.renderPublished(isMuted, showControls);
            }
        },
        renderPublished(isMuted, showControls) {
            this.isMuted = isMuted;
            const singleSizeMode = this.singleSizeMode;
            const e9 = new E9Tags(this.adunit);
            if (store.state.presentationMode) {
                e9.enablePresentationMode();
            }
            window.e9 = e9.toObject();

            let autoplayAllowed = true;
            if (singleSizeMode && isMuted && this.adunit.adFormatId === 77) {
                autoplayAllowed = false;
            }
            var autoplayRequiresMute = false;
            var vjs;

            const initPlayer = () => {
                vjs = videojs(this.$refs.video, {
                    inactivityTimeout: 0,
                    autoplay: false,
                    muted: autoplayRequiresMute && autoplayAllowed,
                    controlBar: {
                        children: showControls ? [
                            'playToggle',
                            'timeDivider',
                            'progressControl',
                            'muteToggle'
                        ] : []
                    },
                    controls: showControls || !autoplayAllowed
                }, () => {
                    this.updateVolume(this.state.publisherPlayerSound);
                });

                vjs.on('volumechange', (e) => {
                    if (!vjs.muted() && this.isMuted) {
                        this.isMuted = false;
                        this.updateVolume(this.state.publisherPlayerSound, true);
                    }
                    if (vjs.muted()) {
                        this.isMuted = true;
                    }
                });

                this.vjs = vjs;
                setupIMA(vjs);
            }

            const setupIMA = () => {
                const adsManagerLoadedCallback = () => {
                    if (autoplayAllowed) {
                        vjs.play();
                    }

                    vjs.ima.addEventListener(google.ima.AdEvent.Type.ALL_ADS_COMPLETED, () => {
                        vjs.controls(showControls);
                        vjs.play();
                    });

                    vjs.ima.addEventListener(google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED, () => {
                        vjs.play();
                        vjs.controls(showControls);
                    });

                    vjs.ima.addEventListener(google.ima.AdEvent.Type.VOLUME_CHANGED, (data) => {
                        vjs.player_.volume(data.currentTarget.getVolume());
                    });
                }

                var options = {
                    id: 'vid2',
                    adLabel: "",
                    showControlsForJSAds: showControls,
                    vpaidMode: google.ima.ImaSdkSettings.VpaidMode.INSECURE,
                    showCountdown: false,
                    disableAdControls: !showControls,
                    timeout: 60000,
                    vastLoadTimeout: 60000,
                    adsManagerLoadedCallback: adsManagerLoadedCallback,
                    adTagUrl: e9.getVPAIDUrl(),
                    debug: true
                };

                vjs.ima(options);
            }
            
            autoplayRequiresMute = isMuted;
            initPlayer();
            this.vjs = vjs;

            var initAdDisplayContainer = function() {
                vjs.ima.initializeAdDisplayContainer();
                wrapperDiv.removeEventListener(startEvent, initAdDisplayContainer);
            }

            var startEvent = 'click';
            if (navigator.userAgent.match(/iPhone/i) ||
                navigator.userAgent.match(/iPad/i) ||
                navigator.userAgent.match(/Android/i)) {
                startEvent = 'touchend';
            }

            var wrapperDiv = document.getElementById('vid2');
            wrapperDiv.addEventListener(startEvent, initAdDisplayContainer);
        },
        renderPreview(isMuted, showControls) {
            const productInfo = this.getProductInfo();
            this.createDivforinstream(productInfo);
            this.writeInstreamContent(productInfo, isMuted, showControls);
        },
        createDivforinstream: function (productInfo) {
            var i = parseInt(productInfo.size.split("x")[0]),
                t = parseInt(productInfo.size.split("x")[1]),
                a = this.$refs.video.parentNode;
            a.id = productInfo.mockId, a.style.height = t, a.style.width = i, document.getElementById(productInfo.container).appendChild(a);
        },
        getProductInfo() {
            let mockId = this.adunit.mediaDataID;
            return {
                size: this.adunit.previewJson.adSize,
                mockId: mockId,
                container: 'adunit'
            }
        },
        writeInstreamContent(productInfo, isMuted, showControls) {
            const e9 = new E9Tags(this.adunit);
            if (store.state.presentationMode) {
                e9.enablePresentationMode();
            }
            if (!this.adunit.previewJson.baseUrl) {
                Object.assign(this.adunit.previewJson, { baseUrl: config.VDX_STUDIO_API_URL });
            }
            const i = {
                ...this.adunit.previewJson
            };
            if (i.teaser) {
                i.teaser.baseUrl = this.adunit.previewJson.baseUrl;
            }
            i[productInfo.size] = {
                states: this.adunit.previewJson.states,
                locale: this.adunit.previewJson.locale,
                peelConfig: this.adunit.previewJson.peelConfig,
                mobileTeaserVideo: this.adunit.previewJson.mobileTeaserVideo
            }; - 1 !== navigator.appVersion.indexOf("MSIE") || navigator.userAgent.indexOf("Opera");
            var t = window.document.getElementById(productInfo.mockId);
            e9.setCustomTags({
                mediaDataID: productInfo.mockId, 
                nonFragmentedVideo: !0,
                creativeBuilderCfg: i
            });
            t.e9 = e9.toObject();
            window.e9 = t.e9;
            const vpaidUrl = e9.getVPAIDUrl();
            this.startVpaid(vpaidUrl, isMuted, showControls);
        },
        startVpaid(e, isMuted, showControls) {
            const self = this;
            Math.round((new Date).getTime() / 1e3);
            var a, i, n = !1, wrapperDiv, adStarted = false,
                t = !1;

            function d() {
                a = videojs(self.$refs.video, {
                        inactivityTimeout: 6e4,
                        preload: "auto",
                        playsinline: !0,
                        autoPlayAdBreaks: !1,
                        controls: !1,
                        autoplay: !1,
                        muted: isMuted
                    }),
                    function (e, i) {
                        if (!window.init) {
                            console.log('Init IMA');
                            var t = {
                                vastLoadTimeout: 5e3,
                                id: "vid2",
                                vpaidMode: google.ima.ImaSdkSettings.VpaidMode.INSECURE,
                                showControlsForJSAds: showControls,
                                showCountdown: false,
                                disableAdControls: !showControls,
                                debug: !0,
                                autoPlayAdBreaks: !1,
                                adsManagerLoadedCallback: function () {
                                    a.ima.playAdBreak(), a.ima.addEventListener("adsready", function () {
                                        console.log("ad break listeners")
                                    }), a.ima.addEventListener(google.ima.AdEvent.TypeALL_ADS_COMPLETED, function () {
                                        a.play(), a.controls(showControls)
                                    }), a.ima.addEventListener(google.ima.AdEvent.Type.COMPLETE, function () {}), a.ima.addEventListener(google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED, function () {
                                        a.play(), a.controls(showControls)
                                    }), a.ima.addEventListener(google.ima.AdEvent.Type.STARTED, function () {
                                        adStarted = !0
                                    }), a.ima.addEventListener(google.ima.AdEvent.Type.ALL_ADS_COMPLETED, function () {

                                    }), a.ima.addEventListener(google.ima.AdEvent.Type.VOLUME_CHANGED, function (data) {
                                        a.player_.volume(data.currentTarget.getVolume());
                                    });
                                }.bind(this),
                                adTagUrl: i
                            };
                            e.ima(t), e.ima.setAdBreakReadyListener(function () {
                                console.log("ad ready")
                            }.bind(this)), n || ((navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/Android/i)) && (c = "touchend"), wrapperDiv = document.getElementById("vid2"), wrapperDiv.addEventListener(c, o))
                        }
                        window.init = !0
                    }(window.vjs = a, e)
            }

            function o() {
                a.ima.initializeAdDisplayContainer(), wrapperDiv.removeEventListener(c, o)
            }
            var c = "click";
            d();
        },
        postMessageListener(event) {
            const protocol = 'showcase://';
            /*
            if (event.data.indexOf('adCanPlay') !== -1) {
                this.updateVolume(this.state.publisherPlayerSound, true);
            }*/
            if (typeof event.data === 'string' && event.data.startsWith(protocol)) {
                const data = JSON.parse(event.data.substring(protocol.length));
                if (data.type && data.type === 'changePublisherPlayerVolume') {
                    this.updateVolume(data.value, true);
                }
            }
        },
        updateVolume(newVolume, updateAdUnitVolume = false) {
            let volume = this.isMuted ? 0 : 1;
            if (newVolume && !isNaN(newVolume * 1)) {
                volume = newVolume * 1;
            }
            this.vjs.volume(volume);
            if (updateAdUnitVolume && this.vjs?.ima?.controller) {
                this.vjs.ima.controller.setVolume(volume);
            }
        }
    }
}