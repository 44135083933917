export default {
    bind: function(el, binding) {
        var words = 15;
        var str = '';
        words = 1 * binding.value;
        for (var w = 0; w < words; w++) str += new Array(Math.floor(2 + Math.random() * 5)).join('█') + ' ';
        el.textContent = str;
        el.setAttribute('fill', binding.value);
    }
}
